import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';
import FolderZipOutlinedIcon from '@mui/icons-material/FolderZipOutlined';
import PictureAsPdfOutlinedIcon from "@mui/icons-material/PictureAsPdfOutlined";
import ArticleOutlinedIcon from "@mui/icons-material/ArticleOutlined";
import ImageOutlinedIcon from '@mui/icons-material/ImageOutlined';
import GetFileType from "./GetFileType";
import { toolingValidExtensions, validExtensions } from '../pages/Products/EditItem/Constants';

const GetIconByFileType = (fileName) => {
    const fileType = GetFileType(fileName).fileExtension;
    
    switch (fileType) {
        case validExtensions.pdf:
        return <PictureAsPdfOutlinedIcon sx={{ fontSize: 40, opacity: 0.54 }} />;
        case validExtensions.doc:
        case validExtensions.docx:
        case toolingValidExtensions.xls:
        case toolingValidExtensions.xlsx:
        return <ArticleOutlinedIcon sx={{ fontSize: 40, opacity: 0.54 }} />;
        case validExtensions.ai:
        return <InsertDriveFileOutlinedIcon sx={{ fontSize: 40, opacity: 0.54 }} />;
        case validExtensions.zip:
        return <FolderZipOutlinedIcon sx={{ fontSize: 40, opacity: 0.54 }} />;
        case validExtensions.tif:
        case validExtensions.tiff:
        return <ImageOutlinedIcon sx={{ fontSize: 40, opacity: 0.54 }} />;
    }
};

export default GetIconByFileType;