import React from "react";
import { Dialog, DialogTitle, DialogContent, IconButton, Grid, Box } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { QUOTE_FIELD_SYMBOLS } from "../pages/Products/EditItem/Constants";

const QuoteDetailsComparisonModal = ({ open, onClose, oldValues, newValues }) => {

  const quoteFields = [
    { label: "IP Cube", name: "ipCube" },
    { label: "Freight Cost", name: "freightCost" },
    { label: "Landed Cost", name: "landedCost" },
    { label: "Gross GM Dollars", name: "grossGMDollars" },
    { label: "Gross GM Percent", name: "grossGMPercent" },
    { label: "Net Margin", name: "netMargin" },
    { label: "Net Margin Dollars", name: "netMarginDollars" },
  ];

  const getValueWithSymbol = (fieldName, fieldValue) => {
    const symbol = QUOTE_FIELD_SYMBOLS[fieldName];
    const value = fieldValue || 0;

    const formattedValue = symbol
      ? symbol === "%" // Check for postfix fields
        ? `${value}${symbol}` // Append for postfix
        : `${symbol}${value}` // Prepend for prefix
      : value; // Default case with no symbol

    return formattedValue;
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth className="quote-details-comparison-modal">
      <Box
        className="dialog-header"
      >
        <DialogTitle
          className="dialog-title"
        >
          The quote IPCube will be altered, as it differs from the product IPCube
          <IconButton
            aria-label="close"
            onClick={onClose}
            className="close-button"
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
      </Box>
      <DialogContent>
        <Grid container className="grid-header">
          <Grid item xs={4} className="grid-item">Field</Grid>
          <Grid item xs={4} className="grid-item">Old Value</Grid>
          <Grid item xs={4} className="grid-item">New Value</Grid>
        </Grid>
        <Grid container className="grid-content">
          {quoteFields.map(({ label, name }) => (
            <React.Fragment key={name}>
              <Grid item xs={4} className="grid-item">{label}</Grid>
              <Grid item xs={4} className="grid-item">{getValueWithSymbol(name, oldValues[name])}</Grid>
              <Grid item xs={4} className="grid-item">{getValueWithSymbol(name, newValues[name])}</Grid>
            </React.Fragment>
          ))}
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default QuoteDetailsComparisonModal;
