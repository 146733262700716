import { Box, Card, CardContent, Button, Typography } from "@mui/material";
import React, { useState, ChangeEvent, useRef } from "react";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import api from "../../services/ApiService";
import { ApiResponse } from "../../models/ApiResponse";
import { toast } from "react-toastify";
import Papa from "papaparse";
import InfoIcon from '@mui/icons-material/Info';

const BulkUpdate = () => {
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [fileData, setFileData] = useState<any[]>([]);
  const [columnData, setColumnData] = useState<any[]>([]);
  const [isError, setIsError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState("");

  const handleFileChange = async (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    const file = event.target.files?.[0];
    setSelectedFile(file);
  };

  const handleClear = () => {
    setColumnData([]);
    setFileData([]);
    setIsError(false);
    setSelectedFile(null);
    fileInputRef.current.value = "";
  };
  const fileInputRef = useRef(null);

  const handleBrowseClick = () => {
    setColumnData([]);
    setFileData([]);
    setIsError(false);
    fileInputRef.current.click();
  };
  const handleSubmit = async () => {
    const maxSizeInBytes = 5 * 1024 * 1024; // 5MB
    const formData = new FormData();
    if (selectedFile.size < maxSizeInBytes) {
      formData.append("file", selectedFile); // Assuming 'file' is the key expected by the server
      api
        .put<ApiResponse<any>>("/Item/bulk-update", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          if (response.isSuccess) {
            toast.success(response.message);

            if (response?.data?.misMatchedItemIDs?.length > 0) {
              Papa.parse(selectedFile, {
                complete: (result) => {
                  const rowData = result?.data?.slice(0, -1); // Exclude header row
                  const filteredArray = rowData?.filter((item: any) => response?.data?.misMatchedItemIDs?.includes(Number(item?.PrjCode)));

                  const columns = filteredArray
                    ? Object.keys(filteredArray[0]).map((field) => ({
                        field: field == "PrjCode" ? "id" : field,
                        headerName: field,
                        width: 160,
                      }))
                    : [];

                  const convertedData = filteredArray
                    ? filteredArray.map((item, index) => {
                        const updatedItem = { ...item, id: item?.PrjCode || index + 1 };
                        delete updatedItem.Id;
                        return updatedItem;
                      })
                    : [];                  

                  if (response?.data?.recordsNotUpdated > 0) {
                    setErrorMessage("Below fields cannot be updated");
                    setIsError(true);
                    setSelectedFile(null);
                  } else {
                    setSelectedFile(null);
                    setIsError(false);
                  }
                  fileInputRef.current.value = "";
                  setColumnData(columns);
                  setFileData(convertedData);
                },
                header: true,
              });
            } else {
              setSelectedFile(null);
              fileInputRef.current.value = "";
            }
          }
        })
        .catch((error) => {
          if (error?.response?.data?.errors?.length > 0) {
            const errorResponse = error.response.data;
            Papa.parse(selectedFile, {
              complete: (result) => {
                const rowData = result?.data; // Exclude header row
                let errorRowData;
                if(errorResponse.message.includes("Paired CVL ")) {
                  errorRowData = rowData?.filter((item: any, index: number) => errorResponse?.errors?.includes(String(index + 1)));
                }
                else {
                  errorRowData = rowData?.filter((item: any) => 
                    errorResponse?.errors?.includes(item?.PrjCode) || errorResponse?.errors?.includes(item?.Stockcode)
                  );
                }

                if (errorRowData?.length > 0) {
                  const columns = errorRowData
                    ? Object.keys(errorRowData[0]).map((field) => ({
                      field,
                      headerName: field,
                      width: 150,
                    }))
                    : [];

                  const convertedData = errorRowData
                    ? errorRowData.map((item, index) => {
                      const updatedItem = {
                        ...item,
                        id: `${item?.PrjCode}-${index + 1}`, // Create a unique id by combining PrjCode and index
                      };
                      return updatedItem;
                    })
                    : [];
                  
                  setColumnData(columns);
                  setFileData(convertedData);
                }
                
                fileInputRef.current.value = "";
                setErrorMessage(errorResponse.message);
                setSelectedFile(null);
                setIsError(true);
              },
              header: true,
            });
          }
          console.error("Exception from update file", error);
        });
    } else {
      toast.error("Upload file less than 5MB");
    }
  };
  const handleUpdateSampleDownload = () => {
    const demoCsvUrl = "./sampleBulkUpdate.csv";
    const link = document.createElement("a");
    link.href = demoCsvUrl;
    link.setAttribute("download", "sampleBulkUpdate.csv");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  return (
    <>
      <Card sx={{ marginBottom: 1 }}>
        <CardContent>
          <div
            style={{
              color: "#454545",
              padding: "20px",
              borderBottom: "1px solid #ccc",
              fontSize: "20px",
              fontStyle: "normal",
              fontWeight: "400",
              lineHeight: "normal",
              textTransform: "capitalize",
            }}
          >
            <p style={{ margin: "0px" }}>Bulk Product Update </p>
            <div style={{ display: "flex", alignItems: "start" }}>
              <div>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <InfoIcon sx={{ fontSize: 18, marginRight: "2px" }} />
                  <p style={{ fontSize: "12px", margin: "0" }}>Notes:</p>
                </div>
                <ul style={{ fontSize: "12px", marginTop: "4px", paddingLeft: "20px" }}>
                  <li>The CVL field value has to be present in the respective CVL values otherwise the value from your file will be ignored and updated with Blank.</li>
                </ul>
              </div>
            </div>
          </div>
          <Box sx={{ height: 1, backgroundColor: "#ccc" }} />
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              alignItems: "flex-start",
              padding: "20px",
            }}
          >
            <input
              type="file"
              id="fileInput"
              ref={fileInputRef}
              style={{ display: "none" }}
              onChange={handleFileChange}
              accept=".csv"
            />
            <div
              className="inputWrap"
              style={{ width: "40%", marginRight: "10px", maxWidth: "100%" }}
            >
              <input
                type="text"
                name="fileName"
                id="fileName"
                value={selectedFile ? selectedFile.name : ""}
                style={{
                  width: "100%",
                  height: "35px",
                }}
                readOnly
              />

              <p
                style={{
                  color: "#717171",
                  fontSize: "15px",
                  fontStyle: "normal",
                  fontWeight: "400",
                  lineHeight: "normal",
                  letterSpacing: "0.17px",
                }}
              >
                CSV (max. 5MB).
                <span
                  onClick={handleUpdateSampleDownload}
                  style={{ cursor: "pointer", paddingLeft: "10px" }}
                >
                  <strong>Download sample CSV for bulk update</strong>
                </span>
              </p>
            </div>

            <Button
              onClick={handleBrowseClick}
              variant="contained"
              style={{
                borderRadius: "0px",
                border: "0.5px solid grey",
                width: "150px",
                height: "35px",
                marginRight: "10px",
              }}
            >
              Browse
            </Button>

            <Button
              onClick={handleSubmit}
              style={{
                borderRadius: "0px",
                border: "0.5px solid grey",
                width: "150px",
                height: "35px",
                marginRight: "10px",
              }}
              disabled={selectedFile ? false : true}
            >
              Submit
            </Button>
            <Button
              onClick={handleClear}
              style={{
                borderRadius: "0px",
                border: "0.5px solid grey",
                width: "150px",
                height: "35px",
              }}
            >
              Clear
            </Button>
          </Box>

          {isError ? (
            <>
              <div
                style={{
                  color: "red",
                  padding: "20px",
                  borderBottom: "1px solid #ccc",
                  fontSize: "20px",
                  fontStyle: "normal",
                  fontWeight: "400",
                  lineHeight: "normal",
                  textTransform: "capitalize",
                }}
              >
                <p style={{ margin: "0px" }}>{errorMessage}</p>
              </div>
              <Box className="bulk-update-grid-cell" sx={{ height: "100%", width: "100%" }}>
                {fileData.length > 0 && (
                  <DataGrid
                    rows={fileData}
                    columns={columnData}
                    slots={{ toolbar: GridToolbar }}
                    slotProps={{
                      toolbar: {
                        showQuickFilter: true,
                      },
                    }}
                    disableColumnFilter
                    disableColumnSelector
                    disableDensitySelector
                    disableRowSelectionOnClick={true}
                    getRowHeight={() => 'auto'}
                  />
                )}
              </Box>
            </>
          ) : (
            ""
          )}
        </CardContent>
      </Card>
    </>
  );
};
export default BulkUpdate;
