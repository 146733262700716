import React, { useEffect, useState, useMemo, useRef, useCallback } from "react";
import { TextField, Autocomplete, Button, MenuItem, InputAdornment, Box, Card, CardContent, IconButton, FormControlLabel, Divider, Fab} from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from '@mui/icons-material/Remove';
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import { FormControl } from "@mui/base";
import AttachmentsUploader, { AttachmentsUploaderRef, MultipleAttachment } from "../../../components/AttachmentsUploader";
import { ApiResponse } from "../../../models/ApiResponse";
import { KeyValues } from "../../../models/Items/FilterOptions";
import api from "../../../services/ApiService";
import moment from "moment";
import { useFormik } from "formik";
import { DATE_FORMAT } from "../../../utils/constants";
import { Item } from "../../../models/Items/Item";
import {  BILL_OF_MATERIALS_LAYOUT_CATEGORY, DEVELOPMENT_TYPE_DROPDOWN_OPTIONS, FIELDS_WITH_DISPLAY_TEXT, FIELD_NAMES, FILE_UPLOAD_CHUNK_SIZE, ITEM_STATUS_DROPDOWN_OPTIONS, ITEM_STATUS_ENUM, MAX_SIZE_FOR_REGULAR_UPLOAD, PROJECT_MANAGER_ASSIGNMENT, QUOTE_APPROVAL_TYPE_DROPDOWN_OPTIONS, QUOTE_FIELD_SYMBOLS, TOOLING_ATTACHMENT_LABEL, USER_ASSIGNMENTS_VALIDATION_MESSAGES, attachmentLevels, attachmentsValidationMessages, dataFieldNames, initialValues, validExtensions, validations } from "./Constants";
import { EditItemDropdownOptions } from "../../../models/Items/EditItemDropdownOptions";
import { toast } from "react-toastify";
import Select from '@mui/material/Select';
import { useNavigate, useParams } from "react-router-dom";
import { AttachmentDetail } from "../../../models/AttachmentDetail";
import AutoAwesomeOutlinedIcon from '@mui/icons-material/AutoAwesomeOutlined';
import ConfirmationModal from "../../../components/ConfirmationModal";
import CloseIcon from "@mui/icons-material/Close";
import Tags from "../../../components/Tags";
import { Tag } from "../../../models/Tag";
import EditProductGridView from "./EditProductGridView";
import UserAssignments, { UserAssignmentFieldValue, UserDropdownOptions } from "../../../components/UserAssignments";
import { UserAssignment } from "../../../models/UserAssignment";
import SessionService from "../../../services/SessionService";
import { Permissions, PermissionsFriendlyNames } from "../../../models/Enum";
import { BomGridView } from "../../../models/Items/BomGridView";
import { MRT_ColumnDef, MRT_Row, MRT_TableContainer, useMaterialReactTable } from "material-react-table";
import { Delete } from "@mui/icons-material";
import { ProductLayoutCategoryDetail } from "../../../models/Items/ProductLayoutCategoryDetail";
import AutoCompleteStockCode from "../../../components/AutoCompleteStockCode";
import ToggleSwitchButton from "../../../components/ToggleSwitchButton";
import ProductComments, { NewComment } from "../../../components/ProductComments";
import { ProductComment } from "../../../models/ProductComment";
import { ProductLayoutFieldDetail } from "../../../models/Items/ProductLayoutFieldDetail";
import { BomType } from "../../../models/BomType";
import StageCheckList from "../../../components/StageCheckList";
import { ProductStageCheckListDetail } from "../../../models/ProductStageCheckListDetail";
import { PrjStageWithStatusAndTag } from "../../../models/PrjStage";
import DeleteValidationModal from "../../../components/ManageSettingComponents/DeleteValidationModal";
import BOMComponentModal from "../../../components/CreateBOMComponent";
import { User } from "../../../models/User";
import AcceptedQuote from "../../../components/AcceptedQuote";
import QuoteDetailsComparisonModal from "../../../components/QuoteDetailsComparisonModal";
import { QuoteBasicDetail } from "../../../models/Quotes/QuoteBasicDetail";
import { ToolingDetail } from "../../../models/Tooling/ToolingDetail";

const EditProduct = () => {

  const { editItemId } = useParams();
  const navigate = useNavigate();

  const queryParameters = new URLSearchParams(window.location.search);
  const pageQueryParams = queryParameters.get("page");

  const [productLayoutFields, setProductLayoutFields] = useState<ProductLayoutCategoryDetail[]>([]);
  const [accordianExpanded, setAccordianExpanded] = useState<string[]>([]);
  const [dropDownOptions, setDropDownOptions] = useState<EditItemDropdownOptions>({});
  const [selectedDropDownOptions, setSelectedDropDownOptions] = useState<any>(
    {}
  );
  const [editItem, setEditItem] = useState<Item>(null);
  const [fieldsDisabled, setFieldsDisabled] = useState<string[]>([]);
  const [attachments, setAttachments] = useState<MultipleAttachment[]>([]);
  const [deleteAttachmentIds, setDeleteAttachmentIds] = useState<number[]>([]);
  const [selectedTagOptions, setSelectedTagOptions] = useState<Tag[]>([]);
  const [commentList, setCommentList] = useState<ProductComment[]>([]);
  const [disableSubmit, setDisableSubmit] = useState<boolean>(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [showBaseCodeModificationConfirmModal, setShowBaseCodeModificationConfirmModal] = useState(false);
  const [showAutoAssignmentConfirmModal, setShowAutoAssignmentConfirmModal] = useState(false);
  const [checkListStatus, setCheckListStatus] = useState<ProductStageCheckListDetail[]>([]);

  const [bomList, setBomList] = useState<BomGridView[]>([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedBOMItem, setSelectedBOMItem] = useState(null);
  const [qty, setQty] = useState('');
  const [bomQtyError, setBomQtyError] = useState(false);
  const [bomErrorMessage, setBomErrorMessage] = useState('');
  const [assignmentEditableFields, setAssignmentEditableFields] = useState([]);
  const [sectionOpenHistory, setSectionOpenHistory] = useState<string[]>([]);

  const initialUserAssignment: UserAssignment = {
    userId: "",
    assignmentId: "",
    id: 0
  };

  const [selectedUserAssignments, setSelectedUserAssignments] = useState<UserAssignmentFieldValue[]>([initialUserAssignment]);
  const sessionService = SessionService.getInstance();

  /*
    This dropdown option state are used separately because this options are dependent on the edit product data
    and dropdown options data, it was being overriden when used with same dropdown options state.
   */
  const [statusDropDownOptions, setStatusDropDownOptions] = useState<KeyValues[]>([]);
  const [functionDropDownOptions, setFunctionDropDownOptions]= useState<KeyValues[]>([]);
  const [tagMenuOptions, setTagMenuOptions] = useState<Tag[]>([]);

  const [deleteModal, setDeleteModal] = useState(false);
  const [showButtons, setShowButtons] = useState(false);
  const [showFab, setShowFab] = useState(true);
  const [showAddBOMComponentModal, setShowAddBOMComponentModal] = useState(false);
  const [assignmentRoleDropdownOptions, setAssignmentRoleDropdownOptions] = useState<KeyValues[]>([]);
  const [userDropdownOptions, setUserDropdownOptions] = useState<UserDropdownOptions[]>([]);
  const [autoAssignments, setAutoAssignments] = useState<UserAssignment[]>([]);
  const [selectedQuote, setSelectedQuote] = useState<string>("");
  const [selectedVendorForQuote, setSelectedVendorForQuote] = useState<number>(null);
  const [ipCubeUpdatesModalOpen, setIPCubeUpdatesModalOpen] = useState<boolean>(false);
  const [oldQuoteDetailsForIPCubeUpdates, setOldQuoteDetailsForIPCubeUpdates] = useState<QuoteBasicDetail>(null);
  const [newQuoteDetailsForIPCubeUpdates, setNewQuoteDetailsForIPCubeUpdates] = useState<QuoteBasicDetail>(null);
  
  // these fields stores the reference id and not the actual value e.g. Brand, Category
  const REFERENCE_FIELDS = [
    "certiNote",
    "baseShape",
    "batteryType",
    "bulbShape",
    "bulbShapeCode",
    "canopyShape",
    "category",
    "colorTemperature",
    "cri",
    "department",
    "ledDimmingType",
    "driverLocation",
    "ledDriverType",
    "ledType",
    "lightSource",
    "locationRating",
    "mountingType",
    "reportingBrand",
    "socketType",
    "wireColor",
    "stage",
    "status",
    "royaltyDesigner",
    "marketingDesigner",
    "category",
    "function",
    "brand",
    "style",
    "skuType",
    "bomType",
    "slStatus",
    "countryOfOrigin",
    "skuRank",
    "bcRank",
    "warehouse",
    "vendorMOQType",
    "itemStockingType",
    "primaryFinish",
    "secondaryFinish",
    "thirdFinish",
    "developmentType",
    "primaryMaterial",
    "secondaryMaterial",
    "thirdMaterial",
    "romanticSize",
    "vendor",
    "prop65",
    "itemStatus",
  ];

  const [prjStageTags, setPrjStageTags] = useState<PrjStageWithStatusAndTag[]>([]);

  const uploaderRef = useRef<AttachmentsUploaderRef>(null);
  
  const formik = useFormik({
    initialValues,
    validationSchema: validations,
    onSubmit: async () => {
      try {

        let isValid = true;
        // error messages to display in toast
        const newErrors: any = {};
        
        // Add attachment and Delete attachment logic to submit data in api request
        if (attachments && attachments.length > 0) {
          const containsLevelBC = attachments.some((a) => a.lvl === attachmentLevels.BC);
          const containsLevelSC = attachments.some((a) => a.lvl === attachmentLevels.SC);
          const {
            stockCodeToastError,
            baseCodeToastError,
            stockCodeInputBoxError,
            baseCodeInputBoxError,
            attachmentTypeToastError
          } = attachmentsValidationMessages;

          if ((containsLevelBC && !(formik.values[FIELD_NAMES.baseCode])) ||
            (containsLevelBC && formik.values[FIELD_NAMES.baseCode].trim() === "")) {
            formik.setErrors({ baseCode: baseCodeInputBoxError });
            newErrors.baseCode = baseCodeToastError;
            isValid = false;
          }

          if ((containsLevelSC && !(formik.values[FIELD_NAMES.stockcode])) ||
            (containsLevelSC && formik.values[FIELD_NAMES.stockcode].trim() === "")) {
            formik.setErrors({ stockcode: stockCodeInputBoxError });
            newErrors.stockCode = stockCodeToastError;
            isValid = false;
          }

          const checkAttachmentType = attachments.some((a) => a.attachType == 0 && a.disabled === false);

          if (checkAttachmentType) {
            newErrors.attachType = attachmentTypeToastError;
            isValid = false;
          }

          if (isValid && uploaderRef.current) {
              // Handle the uploading of attachments to API, including small and large files,
              // by separating them into appropriate categories and uploading them accordingly.
              await uploaderRef.current.uploadAttachmentsToAPI(editItemId, formik, containsLevelBC, containsLevelSC);
          }
        }

        // Add user assignment validation check
        if(selectedUserAssignments.length > 0) {
          const checkFirstRowUserAssignment = selectedUserAssignments.some((ua) => ua.userId && ua.assignmentId === "");
          
          const checkUserFromSecondRow = selectedUserAssignments.slice(1).some((ua) => ua.userId === "");
          const checkAssignmentFromSecondRow = selectedUserAssignments.slice(1).some((ua) => ua.assignmentId === "");
  
          const { userError, assignmentRoleError } = USER_ASSIGNMENTS_VALIDATION_MESSAGES;
          if(checkFirstRowUserAssignment) {
            newErrors.assignmentId = assignmentRoleError;
            isValid = false;
          }
  
          if(checkUserFromSecondRow) {
            newErrors.userId = userError;
            isValid = false;
          }
  
          if(checkAssignmentFromSecondRow) {
            newErrors.assignmentId = assignmentRoleError;
            isValid = false;
          }
        }
        
        // Check if there are any errors
        if (isValid === false) {
          // Combine all error messages
          const errorMessage = Object.values(newErrors).join(', ');
          // Display the combined error message in a toast notification
          toast.error(errorMessage);
        }
        
        if (isValid === true) {

          setDisableSubmit(true);
          // Submit Attachment Id in Delete attachment api
          if (deleteAttachmentIds && deleteAttachmentIds.length > 0) {
            for (const id of deleteAttachmentIds) {
              await api.delete<ApiResponse<any>>(
                `/Attachment/remove?AttachmentId=${id}`
              );
            }
            setDisableSubmit(false);
            setDeleteAttachmentIds([]);
          }

          // Check if baseCode is updated then display basecode confirmation dialog
          if (editItem.baseCode && formik.values[FIELD_NAMES.baseCode] && (editItem.baseCode !== (formik.values[FIELD_NAMES.baseCode]).trim())) {
            // Check if project manager assignment exists
            if (checkProjectManagerAssignmentWithProduct()) {
              setShowBaseCodeModificationConfirmModal(true);
            }
            // else fetch auto assignment list
            else {
              fetchAutoAssignmentForProduct();
            }
          }
          // Else submit form data to update product api
          else {
            submitFormDataToItemApi("No");
          }
        }
      } catch (error: any) {
        setDisableSubmit(false);
        console.error("Exception from edit product", error);
      }
    }
  });

  const getFormattedDate = (fieldName: string) => {
    return formik.values[fieldName]
      ? moment(formik.values[fieldName]).format(DATE_FORMAT)
      : null
  };

  const checkAssignmentWithUserId = (): boolean => {
    const getLoggedInUserDetail = sessionService.getCurrentUser();

    // Check if userAssignments exists and has at least one element
    if (editItem?.userAssignments?.length) {
      return editItem.userAssignments.some(
        (ua: UserAssignment) => ua?.userId === getLoggedInUserDetail?.id
      );
    }

    return false; // Return false if userAssignments is empty or undefined
  };

  useEffect(() => {
    // Retrieve the list of visited products from localStorage
    const visitedProducts: Item[] = JSON.parse(localStorage.getItem('visitedProducts')) || [];

    // Function to update the product list when revisited
    const updateVisitedProducts = (product) => {
      // Remove the product if it's already in the list
      const index = visitedProducts.findIndex(p => p.id === product.id);
      if (index !== -1) {
        visitedProducts.splice(index, 1); // Remove the product from its current position
      }
      // Add the product to the beginning of the list
      visitedProducts.unshift(product);

      // Keep the list to a maximum of 8 products
      if (visitedProducts.length > 8) {
        visitedProducts.pop(); // Remove the last product if the length exceeds 10
      }

      // Store the updated list back to localStorage
      localStorage.setItem('visitedProducts', JSON.stringify(visitedProducts));
    };

    if(editItem && editItem?.id) {
      // Example: Update with a product (this could be dynamic based on the page or user action)
      const productInfo = {
        id: editItem.id,
        shortDesc: editItem.shortDesc,
        stockcode: editItem.stockcode,
        baseCode: editItem.baseCode,
        stageDisplayText: editItem.stageDisplayText,
        statusDisplayText: editItem.statusDisplayText
      };

      if(checkAssignmentWithUserId()) updateVisitedProducts(productInfo);
    }
  }, [editItem]);

  /**
   * This function is used to submit product data to api
   * @param baseCodeModificationConfirm - Base code modification confirmation value "Yes" or "No"
   */
  const submitFormDataToItemApi = async (baseCodeModificationConfirm: string) => {
    try {
    const data = {
      ...formik.values,
      sellingLineInStockDate: getFormattedDate(FIELD_NAMES.sellingLineInStockDate),
      slIntroDate: getFormattedDate(FIELD_NAMES.slIntroDate),
      reqDate: getFormattedDate(FIELD_NAMES.reqDate),
      agreedSampleReadyDate: getFormattedDate(FIELD_NAMES.agreedSampleReadyDate),
    };

    if(selectedQuote !== "") {
      data.quoteId = Number(selectedQuote);
      if(selectedVendorForQuote != data?.vendor) {
        toast.warn('Accepted quote vendor is not matched with the product vendor');
      }
    }

    // Add tags data to submit in api request
    data.tags = (selectedTagOptions || []).map(t => t.id);

    // Add user assignments data in api request if first row is not empty
    const checkIsFirstRowEmpty = selectedUserAssignments.slice(0, 1).some((ua) => ua.userId === "" && ua.assignmentId === "");
    data.userAssignments = (checkIsFirstRowEmpty ? [] : selectedUserAssignments);

    // Trim stockcode if it contains value
    if (data.stockcode) {
      data.stockcode = data.stockcode.trim();
    }
    // Trim basecode if it contains value
    if (data.baseCode) {
      data.baseCode = data.baseCode.trim();
    }

    // check if user clicks "Yes" in confirmation dialog then pass flag as true in api
    if(baseCodeModificationConfirm === "Yes") {
      data.changeBaseCodeForAllProducts = true;
    }
    // else user clicks "No" in confirmation dialog or base code value is not changed then pass flag as false in api
    else {
      data.changeBaseCodeForAllProducts = false;
    }

    data.bomDetails = bomList.map(bomItem => {
      return {
        id: bomItem.id, // this is BomDetails table's ID Field
        sequenceNum: bomItem.sequenceNum,
        componentItemId: bomItem.componentItemId, // this is item table's ID Field
        quantity: bomItem.quantity
      } 
    }) || [];

    setDisableSubmit(true);
    // Submit product stage checklist data in api
    if(checkListStatus?.length > 0) {
      const updateCheckListStatusResponse = await api.post<ApiResponse<boolean>>(
        `/CheckList/status-update/${editItemId}`,
        {
          productId: Number(editItemId),
          productStageCheckLists: checkListStatus
        }
      );

      if(updateCheckListStatusResponse?.isSuccess) {
        setDisableSubmit(false);
      }
    }

    setDisableSubmit(true);
    // Submit product data in Update product api
    const updateItemResponse = await api.put<ApiResponse<Item>>(
      "/Item",
      data
    );
    if (updateItemResponse?.isSuccess) {
      setDisableSubmit(false);
      toast.success(updateItemResponse.message);
      refetchProductDetail();
    }
  } catch (error: any) {
    setDisableSubmit(false);
    console.error("Exception from edit product", error);
  }
  };

  useEffect(() => {
    fetchProductLayoutFields();
    getAllDropDownOptions();
    fetchStageTag();
  }, []);

  useEffect(() => {
    fetchEditItemData(editItemId);
    fetchProductCommentList();
    getAssignmentRoleList();
    getUserList();

    return () => {       
      resetStateValue(); 
    };
  }, [editItemId]);

  useEffect(() => {
    const bindDropdowns = async () => {
      if(Object.keys(dropDownOptions).length > 0) {        
        if(editItem) {
          prepareStatusDropDownOptions(editItem.stage);
          prepareFunctionDropDownOptions(editItem.category);
          prepareTagOptions(editItem.stage, editItem.tags);
          fetchAssignmentEditableFields(editItem.userAssignments);
        }
      }
    }
    
    bindDropdowns();    
  }, [dropDownOptions, editItem]);

  const refetchProductDetail = () => {
    fetchEditItemData(editItemId);
  };

  const tagIdMap = prjStageTags.map(i => i.tags.map(i => i.id))
  const uniqueTagsIdList = tagIdMap.flat().filter((value, index, self) => self.indexOf(value) === index);
  
  const fetchStageTag = async () => {
      const stageTagResponse = await api.get<ApiResponse<PrjStageWithStatusAndTag[]>>("/Picklist/stages");
      setPrjStageTags(stageTagResponse.data || []);
  }

  // This is used to check if the project manager assignment is already assigned to the product
  const checkProjectManagerAssignmentWithProduct = () => {
    return assignmentRoleDropdownOptions.some(
      (assignment) =>
        selectedUserAssignments.some(
          (selected) => selected.assignmentId === assignment.id
        ) && assignment.name === PROJECT_MANAGER_ASSIGNMENT
    );
  };

  const fetchMemoizedFunction = useCallback(() => {
    if(editItem) fetchAllAttachments(editItem);
  }, [editItem]);

  useEffect(() => {
    fetchMemoizedFunction(); // Calls fetchMemoizedFunction when `editItem` updates
  }, [fetchMemoizedFunction]);

  const fetchAllAttachments = async (productDetail: Item) => {
    try {
      // First, call getAllAttachmentsList API
      const getAttachmentsData = await api.post<ApiResponse<AttachmentDetail[]>>(
        `/Attachment/list?itemCode=${productDetail.id}`,
        null
      );

      let attachmentsList: MultipleAttachment[] = [];

      if (getAttachmentsData?.data && getAttachmentsData?.data.length > 0) {

        getAttachmentsData.data.map((attachmentItem) => {
          attachmentsList.push({
            attachId: attachmentItem.attachID,
            fileName: attachmentItem.fileName,
            link: attachmentItem.link,
            attachType: attachmentItem.attachmentTypeId,
            attachmentTypeDisplayText: attachmentItem.attachmentTypeDisplayText,
            lvl: attachmentItem.level,
            attachmentDate: attachmentItem.attachmentDate,
            disabled: true,
          });
        });
      }

      // Now, call fetchToolingDetailsBySeriesName API
      if (productDetail.seriesName) {
        const toolingDetailsResponse = await api.get<ApiResponse<ToolingDetail>>(
          `/Tooling/${productDetail.seriesName}`
        );

        if (toolingDetailsResponse?.data?.toolings?.length > 0) {
          toolingDetailsResponse.data.toolings.map((toolingAttachment) => {
            attachmentsList.push({
              fileName: toolingAttachment.fileName,
              link: toolingAttachment.link,
              attachType: 0,
              attachmentTypeDisplayText: TOOLING_ATTACHMENT_LABEL,
              disabled: true,
            });
          });
        }
      }

      // Once both data are fetched, update the state with the complete list
      setAttachments(attachmentsList);

    } catch (error) {
      console.error("Error fetching attachments and tooling details:", error);
    }
  };

  /* 
    This function is used to fetch auto assignment api
   */
  const fetchAutoAssignmentForProduct = () => {
    const baseCodeValue = formik.values[FIELD_NAMES.baseCode];
    try {
      api
        .get<ApiResponse<any[]>>(`/Item/auto-assignments/${editItemId}/${baseCodeValue}`)
        .then((response: ApiResponse<UserAssignment[]>) => {
          if (response.isSuccess) {
            // If auto assignment api response contains data, then display confirmation dialog to auto assign
            if (response.data?.length > 0) {
              setAutoAssignments(response.data);
              setShowAutoAssignmentConfirmModal(true);
            }
            // else display basecode confirmation dialog
            else {
              setShowBaseCodeModificationConfirmModal(true);
            }
          } else {
            throw new Error(response?.message);
          }
        })
        .catch((error) => {
          console.error("Exception from Auto Assignments", error);
        });
    } catch (error: any) {
      console.error(error?.message);
    };
  };

  /* 
    This function is used to get assignment role drop down options from api
   */
    const getAssignmentRoleList = () => {
      try {
        api
          .get<ApiResponse<any[]>>(`/Assignment/list`)
          .then((response: ApiResponse<any[]>) => {
            if (response.isSuccess) {
              if (response.data?.length > 0) {
                const assignments = response.data.filter(a => a.isActive === true)
                .map(u => {
                  return {
                    id: u.id,
                    name: u.name
                  }
                });
                setAssignmentRoleDropdownOptions(assignments);
              }
            } else {
              throw new Error(response?.message);
            }
          })
          .catch((error) => {
            console.error("Exception from Assignments", error);
          });
      } catch (error: any) {
        console.error(error.message);
      }
    };

  /* 
    This function is used to get user drop down options from api
  */
  const getUserList = () => {
    try {
      api
        .get<ApiResponse<User[]>>(`/UserRole`)
        .then((response: ApiResponse<User[]>) => {
          if (response.isSuccess) {
            if (response.data?.length > 0) {
              const users = response.data.filter((ul) => ul.isActive === true).map(ul => {
                return {
                  id: ul.id,
                  name: ul.name,
                  disabled: false
                }
              });
              setUserDropdownOptions(users);
            }
          } else {
            throw new Error(response?.message);
          }
        })
        .catch((error) => {
          console.error("Exception from User Role", error);
        });
    } catch (error: any) {
      console.error(error.message);
    }
  };

  /**
   * This function is used to fetch edit product layout fields from api and set it to state
   */
  const fetchProductLayoutFields = async () => {
    try {
      const response = await api
        .get<ApiResponse<ProductLayoutCategoryDetail[]>>(`/PickList/layoutFields`);
      if (response.isSuccess) {
        let { data } = response;
        // BILL OF MATERIALS category needs to be pushed static in array as it is not present in api response
        data.push(BILL_OF_MATERIALS_LAYOUT_CATEGORY);

        // Update fields names recieved from api so that it matches with formRenderer keys to avoid casing errors
        data.map((lf) => {
          lf.fields.map((field) => {
            const matchedName = formRendererNames.find(
              (fn) => fn.toLowerCase() === field.fieldName.toLowerCase()
            );
            if (matchedName) {
              field.fieldName = matchedName;
            }
          });
        });
        setProductLayoutFields(data);
        setAccordianExpanded([data[0]?.categoryName]);
      } else {
        throw new Error(response.message);
      }
    } catch (error: any) {
      console.error(error?.message);
    }
  };

  /**
   * This function is used to get single product api data and set it to form
   * @param editItemParams - props object that includes product id and stockcode
   */
  const fetchEditItemData = async (editItemParams: any) => {
    try {
      return api
        .get<ApiResponse<Item>>(`/Item/${editItemParams}`)
        .then((response: ApiResponse<Item>) => {
          if (response.isSuccess) {
            const { data } = response;
            formik.setValues({ ...data });
            setEditItem(data);
            setBomList(data.bomDetails);
            setSelectedQuote(String(data?.quoteId || ""));
            return data;
          } else {
            throw new Error(response.message);
          }
        })
        .catch((error) => {
          console.error("Exception from Get single product API", error);
          return null;
        });
    } catch (error: any) {
      console.error(error.message);
    }
  };

 

  /* 
    This function is used to get drop down options data from api
   */
  const getAllDropDownOptions = async () => {
    try {
      // using same filter options that we are using in Product Listing screen + some other drop down using separate API
      const dropDownSources = await api.get<
            ApiResponse<EditItemDropdownOptions>
          >(`/Item/active-dropdown-options`);
      // get data for the BOM type List
      const bomTypeDataResponse = await api.get<
            ApiResponse<BomType[]>
          >(`/PickList/bomtypes`);

      if (dropDownSources?.isSuccess && bomTypeDataResponse?.isSuccess) {
        
        const complianceOptions = [
          { id: 1, name: "Y", description: "Yes" },
          { id: 2, name: "N", description: "No" }
        ];
        dropDownSources.data.complianceOptions = complianceOptions;
        //Append dropdwon sources with the BOM Type response
        dropDownSources.data.bomType = bomTypeDataResponse.data;
        
        setDropDownOptions(dropDownSources.data);
        return dropDownSources.data;
      }
    } catch (error) {
      console.error("Exception from API", error);
    }
  };

  /**
   * This function is used to fetch product comments from api and set it to state
   */
  const fetchProductCommentList = async () => {
    const sessionInfo: any = JSON.parse(
      window?.localStorage.getItem("session") || "{}"
    );
    try {
      const response = await api
        .get<ApiResponse<ProductComment[]>>(`/ProductComment/list/${editItemId}`, {
          headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + sessionInfo.token,
          },
        }
        );
      if (response.isSuccess) {
        let { data } = response;
        setCommentList(data);
      } else {
        throw new Error(response?.message);
      }
    } catch (error: any) {
      console.error(error?.message);
    }
  };

  /**
   * This function is used to fetch assignment editable fields if session user doesn't have update product permission and user associated with assignment
   */
  const fetchAssignmentEditableFields = async (userAssignments: UserAssignment[]) => {
    const sessionUser = sessionService.getCurrentUser();
    const assignmentForSessionUser = userAssignments?.find((ua) => ua.userId === sessionUser.id);
    
    if (checkItemFormFieldPermission() && assignmentForSessionUser) {
      const { assignmentId } = assignmentForSessionUser;

      try {
        const response = await api
          .get<ApiResponse<string[]>>(`/Assignment/editable-fields/${assignmentId}`);
        
        if (response.isSuccess) {
          let { data } = response;
          // Update fields names recieved from api so that it matches with formRenderer keys to avoid casing errors
          const editableFieldNames: string[] = data?.reduce((acc, field) => {
            const matchedFieldName = formRendererNames.find(
              (fn) => fn.toLowerCase() === field.toLowerCase()
            );
            
            if (matchedFieldName) {
              acc.push(matchedFieldName);
            }
            return acc;
          }, []);

          setAssignmentEditableFields(editableFieldNames);
        } else {
          throw new Error(response?.message);
        }
      } catch (error: any) {
        console.error(error?.message);
      }
    }
  };

  /* 
    This function is used to set dropdown values of the product currently being edited
   */
  const setDropdownValuesFromEditItem = () => {
    setSelectedDropDownOptions({
      ...selectedDropDownOptions,
      // START : new CVL Fields
      certiNote: editItem.certiNote ?
      {
        id: editItem.certiNote,
        name: editItem.certiNoteDisplayText
      } : undefined,
      baseShape: editItem.baseShape ?
      {
        id: editItem.baseShape,
        name: editItem.baseShapeDisplayText
      } : undefined,
      batteryType: editItem.batteryType ?
      {
        id: editItem.batteryType,
        name: editItem.batteryTypeDisplayText
      } : undefined,
      bulbShape: editItem.bulbShape ?
      {
        id: editItem.bulbShape,
        name: editItem.bulbShapeDisplayText
      } : undefined,
      bulbShapeCode: editItem.bulbShapeCode ?
      {
        id: editItem.bulbShapeCode,
        name: editItem.bulbShapeCodeDisplayText
      } : undefined,
      canopyShape: editItem.canopyShape ?
      {
        id: editItem.canopyShape,
        name: editItem.canopyShapeDisplayText
      } : undefined,
      colorTemperature: editItem.colorTemperature ?
      {
        id: editItem.colorTemperature,
        name: editItem.colorTemperatureDisplayText
      } : undefined,
      cri: editItem.cri ?
      {
        id: editItem.cri,
        name: editItem.criDisplayText
      } : undefined,
      department: editItem.department ?
      {
        id: editItem.department,
        name: editItem.departmentDisplayText
      } : undefined,
      ledDimmingType: editItem.ledDimmingType ?
      {
        id: editItem.ledDimmingType,
        name: editItem.ledDimmingTypeDisplayText
      } : undefined,
      driverLocation: editItem.driverLocation ?
      {
        id: editItem.driverLocation,
        name: editItem.driverLocationDisplayText
      } : undefined,
      ledDriverType: editItem.ledDriverType ?
      {
        id: editItem.ledDriverType,
        name: editItem.ledDriverTypeDisplayText
      } : undefined,
      ledType: editItem.ledType ?
      {
        id: editItem.ledType,
        name: editItem.ledTypeDisplayText
      } : undefined,
      lightSource: editItem.lightSource ?
      {
        id: editItem.lightSource,
        name: editItem.lightSourceDisplayText
      } : undefined,
      locationRating: editItem.locationRating ?
      {
        id: editItem.locationRating,
        name: editItem.locationRatingDisplayText
      } : undefined,
      mountingType: editItem.mountingType ?
      {
        id: editItem.mountingType,
        name: editItem.mountingTypeDisplayText
      } : undefined,
      reportingBrand: editItem.reportingBrand ?
      {
        id: editItem.reportingBrand,
        name: editItem.reportingBrandDisplayText
      } : undefined,
      socketType: editItem.socketType ?
      {
        id: editItem.socketType,
        name: editItem.socketTypeDisplayText
      } : undefined,
      wireColor: editItem.wireColor ?
      {
        id: editItem.wireColor,
        name: editItem.wireColorDisplayText
      } : undefined,
      skuRank: editItem.skuRank ?
      {
        id: editItem.skuRank,
        name: editItem.skuRankDisplayText
      } : undefined,
      bcRank: editItem.bcRank ?
      {
        id: editItem.bcRank,
        name: editItem.bcRankDisplayText
      } : undefined,
      vendorMOQType: editItem.vendorMOQType ?
      {
        id: editItem.vendorMOQType,
        name: editItem.vendorMOQTypeDisplayText
      } : undefined,
      itemStockingType: editItem.itemStockingType ?
      {
        id: editItem.itemStockingType,
        name: editItem.itemStockingTypeDisplayText
      } : undefined,
      warehouse: editItem.warehouse ?
      {
        id: editItem.warehouse,
        name: editItem.warehouseDisplayText
      } : undefined,
      slStatus: editItem.slStatus ?
      {
        id: editItem.slStatus,
        name: editItem.slStatusDisplayText
      } : undefined,
      romanticSize: editItem.romanticSize ?
      {
        id: editItem.romanticSize,
        name: editItem.romanticSizeDisplayText
      } : undefined,
      developmentType: editItem.developmentType ?
      {
        id: editItem.developmentType,
        name: editItem.developmentTypeDisplayText
      } : undefined,
      // END : new CVL Fields
      brand: editItem.brand ? 
      {
        id: editItem.brand,
        name: editItem.brandDisplayText
      } : undefined,
      marketingDesigner: editItem.marketingDesigner ? 
      {
        id: editItem.marketingDesigner,
        name: editItem.marketingDesignerDisplayText
      } : undefined,
      royaltyDesigner: editItem.royaltyDesigner ? 
      {
        id: editItem.royaltyDesigner,
        name: editItem.royaltyDesignerDisplayText
      } : undefined,
      countryOfOrigin: editItem.countryOfOrigin ? 
      {
        id: editItem.countryOfOrigin,
        name: editItem.countryOfOriginDisplayText
      } : undefined,
      style: editItem.style ? 
      {
        id: editItem.style,
        name: editItem.styleDisplayText
      } : undefined,
      category: editItem.category ? 
      {
        id: editItem.category,
        name: editItem.categoryDisplayText
      } : undefined,
      function: editItem.categoryDisplayText && editItem.function ? 
      {
        id: editItem.function,
        name: editItem.functionDisplayText
      } : undefined,
      skuType: editItem.skuType ? 
      {
        id: editItem.skuType,
        name: editItem.skuTypeDisplayText
      } : undefined,
      stage: editItem.stage ? 
      {
        id: editItem.stage,
        name: editItem.stageDisplayText
      } : undefined,
      status: editItem.stageDisplayText && editItem.status ?
      {
        id: editItem.status,
        name: editItem.statusDisplayText
      } : undefined,
      prop65: editItem.prop65 ?
      {
        id: editItem.prop65,
        description: editItem.prop65DisplayText
      } : undefined,
      primaryFinish: editItem.primaryFinish ?
      {
        id: editItem.primaryFinish,
        code: editItem.primaryFinishCode,
        name: editItem.primaryFinishName
      } : undefined,
      secondaryFinish: editItem.secondaryFinish ?
      {
        id: editItem.secondaryFinish,
        code: editItem.secondaryFinishCode,
        name: editItem.secondaryFinishName
      } : undefined,
      thirdFinish: editItem.thirdFinish ?
      {
        id: editItem.thirdFinish,
        code: editItem.thirdFinishCode,
        name: editItem.thirdFinishName
      } : undefined,
      primaryMaterial: editItem.primaryMaterial ? 
      {
        id: editItem.primaryMaterial,
        name: editItem.primaryMaterialDisplayText
      } : undefined,
      secondaryMaterial: editItem.secondaryMaterial ? 
      {
        id: editItem.secondaryMaterial,
        name: editItem.secondaryMaterialDisplayText
      } : undefined,
      thirdMaterial: editItem.thirdMaterial ? 
      {
        id: editItem.thirdMaterial,
        name: editItem.thirdMaterialDisplayText
      } : undefined,
      vendor: editItem.vendor ? 
      {
        id: editItem.vendor,
        name: editItem.vendorDisplayText
      } : undefined,
      itemStatus: editItem.itemStatus.replace(/([a-z])([A-Z])/g, "$1 $2"),
      certiType: editItem.certiType ? editItem.certiType : undefined,
      mounting: editItem.mounting ? editItem.mounting : undefined,
      priMtrlManufMethod: editItem.priMtrlManufMethod ? editItem.priMtrlManufMethod : undefined,
      secMtrlManufMethod: editItem.secMtrlManufMethod ? editItem.secMtrlManufMethod : undefined,
      thirdMtrlManufMethod: editItem.thirdMtrlManufMethod ? editItem.thirdMtrlManufMethod : undefined,
      hardwareMaterial: editItem.hardwareMaterial ? editItem.hardwareMaterial : undefined,
      baseMaterial: editItem.baseMaterial ? editItem.baseMaterial : undefined,
      packaging: editItem.packaging ? editItem.packaging : undefined,
      jBox: editItem.jBox ? editItem.jBox : undefined,
      ledColorTemp: editItem.ledColorTemp ? editItem.ledColorTemp : undefined,
      riser: editItem.riser ? editItem.riser : undefined,
      knobType: editItem.knobType ? editItem.knobType : undefined,
      htsMaterial: editItem.htsMaterial ? editItem.htsMaterial : undefined,
      htsMaterialDtl: editItem.htsMaterialDtl ? editItem.htsMaterialDtl : undefined,
      subContVendor1: editItem.subContVendor1 ? editItem.subContVendor1 : undefined,
      subContVendor2: editItem.subContVendor2 ? editItem.subContVendor2 : undefined,
      subContVendor3: editItem.subContVendor3 ? editItem.subContVendor3 : undefined,
      subContVendor4: editItem.subContVendor4 ? editItem.subContVendor4 : undefined,
      subContVendor5: editItem.subContVendor5 ? editItem.subContVendor5 : undefined,
    });

    setDropDownDisabled(editItem);
  };

  useEffect(() => {
    if (editItem) {
      setDropdownValuesFromEditItem();
      setTagValuesFromEditItem();
      setUserAssignmmentsFromEditItem();
    };
  }, [editItem]);

  const resetStateValue = () => {
    formik.resetForm(); 
    setAttachments([]);
    setDeleteAttachmentIds([]);
    setTagMenuOptions([]);
    setSelectedTagOptions([]);
    setSelectedUserAssignments([initialUserAssignment]);
    setCommentList([]);
    setAssignmentEditableFields([]);
  };

  const checkItemFormFieldPermission = () => {
    return !sessionService.hasPermission(Permissions.UpdateProduct);
  }

  const checkAssignmentFormFieldPermission = (fieldName: string) => {
    return !(assignmentEditableFields.includes(fieldName))
  };

  const checkBOMPermission = () => {
    return !sessionService.hasPermission(Permissions.ManageBOM);
  };

  const checkItemStatus = () => {
    return editItem?.itemStatus != ITEM_STATUS_ENUM.Active
  }

  const isFieldDisabled = (fieldName: string) => {
    if (fieldName === FIELD_NAMES.itemStatus) {
      return false;
    };
    
    return (checkItemFormFieldPermission() && checkAssignmentFormFieldPermission(fieldName)) || checkItemStatus();
  };

  // This function is used to set selected tag options from edit product api
  const setTagValuesFromEditItem = () => {
    if (editItem?.tags && editItem.tags.length > 0) {
      const tagsValue = editItem.tags.map(tag => {
        return {
          id: tag.id,
          name: tag.name
        }
      });
      setSelectedTagOptions(tagsValue);
    }
  };

  // This function is used to set selected user assignment(s) from edit product api
  const setUserAssignmmentsFromEditItem = () => {
    if(editItem?.userAssignments && editItem.userAssignments.length > 0) {
      setSelectedUserAssignments(editItem.userAssignments);
    }
  };

  /**
   * This function is used to set tag menu options
   * @param stageId - Stage Id
   * @param selectedTags - Selected tags
   */
  const prepareTagOptions = (stageId: number, selectedTags: Tag[]) => {
    if (stageId && dropDownOptions?.stageStatusTags?.length) {
      const stageWiseTagOptionsForDropdown = dropDownOptions.stageStatusTags
        .find(stage => stage.id == stageId)
        .tags
        .filter(t => !selectedTags.some((st) => st.id === t.id))
        .map(s => {
          return {
            id: s.id,
            name: s.name
          }
        });
        
      setTagMenuOptions(stageWiseTagOptionsForDropdown);
    }
  };

  /**
   * This function is used to set dropdown field enable / disable based on dependent field value
   * @param item - Edit item state that contains api data
   */
  const setDropDownDisabled = (item: Item) => {
    let disabledItems = [...fieldsDisabled];
    
    if(item.stageDisplayText) {
      const isStatusExists = disabledItems.includes(FIELD_NAMES.status);
      if(isStatusExists) {
        disabledItems = disabledItems.filter(item => item !== FIELD_NAMES.status);
      }
    }
    else {
      const isStatusExists = disabledItems.includes(FIELD_NAMES.status);
      if(!isStatusExists) {
        disabledItems = [...disabledItems, FIELD_NAMES.status];
      }
      formik.setFieldValue(FIELD_NAMES.status, undefined);
    }

    if(item.categoryDisplayText) {
      const isFunctionExists = disabledItems.includes(FIELD_NAMES.function);
      if(isFunctionExists) {
        // Remove the product from the array
        disabledItems = disabledItems.filter(item => item !== FIELD_NAMES.function);
      }
    }
    else {
      const isFunctionExists = disabledItems.includes(FIELD_NAMES.function);
      if(!isFunctionExists) {
        disabledItems = [...disabledItems, FIELD_NAMES.function];
      }
      formik.setFieldValue(FIELD_NAMES.function, undefined);
    }
    setFieldsDisabled(disabledItems);
  };

  /**
   * This function is used to handle Autocomplete dropdown value
   * @param fieldName - Name of the field
   * @param selectedItem - Selected option value
   */
  const handleAutocompleteOptionsChange = (fieldName, selectedItem) => {
    if (!selectedItem) {
      formik.setFieldValue(fieldName, undefined);
      setSelectedDropDownOptions({
        ...selectedDropDownOptions,
        [fieldName]: undefined,
      });
    } else {
      /* 
        selectedValue variable logic: 
        if dropdown option contains string type value, then string value will be selected.
        else if Reference fields array includes dropdown field name, then id will be selected.
        else name will be selected.
      */
      const selectedValue =
        typeof selectedItem === "string"
          ? selectedItem
          : REFERENCE_FIELDS.includes(fieldName)
            ? selectedItem.id
            : selectedItem.name || selectedItem.description;

      formik.setFieldValue(fieldName, selectedValue);

      // Check if the fieldName exists in FIELDS_WITH_DISPLAY_TEXT and set its mapped display field
      if (FIELDS_WITH_DISPLAY_TEXT.hasOwnProperty(fieldName)) {
        formik.setFieldValue(FIELDS_WITH_DISPLAY_TEXT[fieldName], selectedItem.name || selectedItem.description);
      }

      setSelectedDropDownOptions({
        ...selectedDropDownOptions,
        [fieldName]: selectedItem
      });
    }
  };

  /**
   * This function is used to remove array product from state and enable the dropdown field
   * @param fieldName - Name of the field to enable it
   */
  const setDropDownFieldEnabled = (fieldName: string) => {
    const isFieldExists = fieldsDisabled.includes(fieldName);

    if (isFieldExists) {
      // Remove the product from the array
      setFieldsDisabled(fieldsDisabled.filter(sec => sec !== fieldName));
    }
  };

  const getFieldBackgroundColor = (fieldName) => {
    return isFieldDisabled(fieldName) ? "#EBEBE4" : "#FFFFFF";
  };

  /**
   * This function is used to render text field
   * @param fieldName - Name of the field
   * @param label - Label of the field
   */
  const renderTextField = (fieldName = "", label = "") => (
    <FormControl style={{ margin: "5px 5px", width: "100%" }}>
      <label
        htmlFor={`outlined-${label}`}
        style={{ textTransform: "capitalize" }}
      >
        {label}
        {(fieldName === FIELD_NAMES.shortDesc) && <span className="required-field-asterisk-icon">{` *`}</span>}
      </label>
      <TextField id={`outlined-${label}`}
        name={fieldName}
        margin="dense"
        label={
          formik.touched[fieldName] && formik.errors[fieldName] ?
            formik.errors[fieldName] : ""
        }
        value={formik.values[fieldName] || ""}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        error={formik.touched[fieldName] && Boolean(formik.errors[fieldName])}
        style={{ backgroundColor: getFieldBackgroundColor(fieldName) }}
        disabled={isFieldDisabled(fieldName)}
      />
    </FormControl>
  );

    /**
   * This function is used to render basecode text field
   * @param fieldName - Name of the field
   * @param label - Label of the field
   */
    const renderTextFieldBaseCodeAuto = (fieldName = "", label = "") => (
      <FormControl style={{ margin: "5px 5px", width: "100%" }}>
        <label
          htmlFor={`outlined-${label}`}
          style={{ textTransform: "capitalize" }}
        >
          {label}
          {(fieldName === FIELD_NAMES.shortDesc) && <span className="required-field-asterisk-icon">{` *`}</span>}
        </label>
        <TextField id={`outlined-${label}`}
          name={fieldName}
          margin="dense"
          label={
            formik.touched[fieldName] && formik.errors[fieldName] ?
              formik.errors[fieldName] : ""
          }
          value={formik.values[fieldName] || ""}
          InputProps={{ 
            startAdornment:(
              (!checkItemFormFieldPermission() || !checkAssignmentFormFieldPermission(fieldName)) ? <InputAdornment position="start">
                <AutoAwesomeOutlinedIcon onClick= {() => checkBaseCodeExist ? handleOpenModal() : handleBaseCodeAuto()} sx = {{cursor:"pointer"}}/>
              </InputAdornment> : null)
          }}
          style={{ backgroundColor: getFieldBackgroundColor(fieldName) }}
          disabled={isFieldDisabled(fieldName)}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched[fieldName] && Boolean(formik.errors[fieldName])}
        />
      </FormControl>
    );

    const handleOpenModal = () => {
      const missingFields = [];
      validateBaseCodeAuto(missingFields);
      if (missingFields.length > 0) {
        toast.error(`${missingFields.join(', ')} ${missingFields.length > 1 ? 'are' : 'is'} required to generate Base Code`);
      }else {
        setShowConfirmModal(true);
      }
    };

    const handleCloseModal = () => {
      setShowConfirmModal(false);
      setDeleteModal(false);
    };

    const getFieldLabel = (fieldName) => {
      if (productLayoutFields?.length > 0) {
        // Flatten fields array from the productLayoutFields state
        let flattenedFields = productLayoutFields.flatMap(category => category.fields);
        let fieldLabel;

        flattenedFields.find((fl) => {
          if (fl.fieldName === fieldName) {
            fieldLabel = fl.label;
          }
        });
        return fieldLabel || fieldName;
      }
    };

    const validateBaseCodeAuto = (missingFields) => {
      const { marketingDesigner, seriesName, category } = formik.values;

      if (!marketingDesigner) {
        missingFields.push(getFieldLabel(FIELD_NAMES.marketingDesigner));
      };
      if (!seriesName) {
        missingFields.push(getFieldLabel(FIELD_NAMES.seriesName));
      };
      if (!category) {
        missingFields.push(getFieldLabel(FIELD_NAMES.category));
      };
    };
  
    const handleBaseCodeAuto = async () => {
      try {
        const baseCodeAutoResponse = await api.post<ApiResponse<any>>("/Item/base-code-automation", {
          marketingDesigner: formik.values.marketingDesigner,
          seriesName: formik.values.seriesName,
          category: formik.values.category,
        })
        if (baseCodeAutoResponse.isSuccess) {
          formik.setFieldValue(FIELD_NAMES.baseCode, baseCodeAutoResponse?.data?.baseCode);
          if (!(formik.values.pdCode)) {
            formik.setFieldValue(FIELD_NAMES.pdCode, baseCodeAutoResponse?.data?.baseCode);
          }
          formik.setFieldValue(FIELD_NAMES.bC_DesignerSeq, baseCodeAutoResponse?.data?.designerSeqNo);
          formik.setFieldValue(FIELD_NAMES.bC_CategorySeq, baseCodeAutoResponse?.data?.categorySeqNo);
          toast.success(baseCodeAutoResponse.message);
          setShowConfirmModal(false);
        }
      } catch (error: any) {
        toast.error(error.message);
      }
    };

    /**
     * This function is used to handle numeric text field value
     * @param fieldName - Name of the field
     * @param value - Value of the field
     */
    const handleNumericFieldChange = (fieldName: string, value: string | number) => {
      if (value === "") {
        formik.setFieldValue(fieldName, null);
      }
      else {
        formik.setFieldValue(fieldName, value);
      }
    };


  /**
   * This function is used to render numeric text field
   * @param fieldName - Name of the field
   * @param label - Label of the field
   */
  const renderNumericField = (fieldName = "", label = "") => (
    <FormControl style={{ margin: "5px 5px", width: "100%" }}>
      <label
        htmlFor={`outlined-${label}`}
        style={{ textTransform: "capitalize" }}
      >
        {label}
      </label>
      <TextField
        id={`outlined-${label}`}
        name={fieldName}
        className="hide-input-arrow"
        inputProps={{ type: "number", step: "any"}}
        margin="dense"
        onKeyDown={(e) => (e.key === "ArrowUp" || e.key === "ArrowDown") && e.preventDefault()}
        onFocus={(e) => e.target.addEventListener("wheel", function (e) { e.preventDefault() }, { passive: false })}
        label={
          formik.touched[fieldName] && formik.errors[fieldName] ?
            formik.errors[fieldName] : ""
        }
        value={formik.values[fieldName] || ""}
        onChange={(e) => {
          handleNumericFieldChange(fieldName, e.target.value);
        }}
        onBlur={formik.handleBlur}
        error={formik.touched[fieldName] && Boolean(formik.errors[fieldName])}
        style={{ backgroundColor: getFieldBackgroundColor(fieldName) }}
        disabled={isFieldDisabled(fieldName)}
      />
    </FormControl>
  );
  /**
   * This function is used to render autocomplete with dropdown option list
   * @param fieldName - Name of the field
   * @param label - Label of the field
   * @param optionList - Dropdown option array list
   * @param optionLabelGetter - Custom label for dropdown option list
   */
  const renderAutocomplete = (
    fieldName: string = "",
    label: string = "",
    optionList: Array<any> | undefined,
    optionLabelGetter: (option) => string = null
  ) => (
    <FormControl style={{ margin: "5px 5px", width: "100%" }}>
      <label
        htmlFor={`outlined-${fieldName}`}
        style={{ textTransform: "capitalize" }}
      >
        {label}
        {(fieldName === FIELD_NAMES.brand) && <span className="required-field-asterisk-icon">{` *`}</span>}
      </label>
      <Autocomplete
        id={`outlined-${fieldName}`}
        value={
          selectedDropDownOptions[fieldName]
            ? selectedDropDownOptions[fieldName]
            : null
        }
        onChange={(event, newValue) =>
          handleAutocompleteOptionsChange(fieldName, newValue)
        }
        onBlur={formik.handleBlur}
        options={optionList ? optionList : []}
        getOptionLabel={(option) =>
          optionLabelGetter ? optionLabelGetter(option) : option.name || option.description || option
        }
        isOptionEqualToValue={(option, value) => option.id === value.id}
        style={{ backgroundColor: getFieldBackgroundColor(fieldName) }}
        disabled={isFieldDisabled(fieldName)}
        renderInput={(params) => (
          <TextField
            error={
              Boolean(formik.errors[fieldName])
            }
            label={formik.errors[fieldName] ? formik.errors[fieldName] : ""}
            key={params.id}
            {...params}
            placeholder="- Select -"
          />
        )}
      />
    </FormControl>
  );

  /**
   * This function is used to render select dropdown option list
   * @param fieldName - Name of the field
   * @param label - Label of the field
   * @param optionList - Dropdown option array list
   */
  const renderDropDown = (
    fieldName: string = "",
    label: string = "",
    optionList: Array<any> | undefined
  ) => (
    <FormControl style={{ margin: "5px 5px", width: "100%" }}>
      <label
        htmlFor={`outlined-${fieldName}`}
        style={{ textTransform: "capitalize" }}
      >
        {label}
      </label>
      <Select
        id={`outlined-${fieldName}`}
        name={fieldName}
        type="text"
        onBlur={formik.handleBlur}
        value={formik.values[fieldName] || ""}
        displayEmpty={true}
        onChange={(event, selectedItem: any) =>{
          handleDropDownOptionsChange(fieldName, selectedItem.props)
        }}
        disabled={fieldsDisabled.includes(fieldName) || isFieldDisabled(fieldName) || (fieldName === FIELD_NAMES.bomType && checkBOMPermission())}
        sx={{
          height: "45px",
          margin: "5px 0",
          backgroundColor: (fieldsDisabled.includes(fieldName) || isFieldDisabled(fieldName) || (fieldName === FIELD_NAMES.bomType && checkBOMPermission())) ? "#EBEBE4" : "#FFFFFF"
        }}
      >
        <MenuItem value="" disabled>Select</MenuItem>
        {optionList && optionList.length > 0 &&
          optionList.map((item) => (
            <MenuItem key={item.id} value={item.id}>
              {/* since item type is any, this is required to get the data field out */}
              {item.description || item.name || item.bomDescription}
            </MenuItem>
          ))}
          <MenuItem value={null}>None</MenuItem>
      </Select>
    </FormControl>
  );

   /**
   * This function is used to render toogle switch button field
   * @param fieldName - Name of the field
   * @param label - Label of the field
   */
   const renderToggleButton = (
    fieldName: string = "",
    label: string = "",
  ) => (
    <FormControl style={{ margin: "5px 5px", width: "100%" }}>
      <label
        htmlFor={`outlined-${fieldName}`}
        style={{ textTransform: "capitalize" }}
      >
        {label}
      </label>
      <FormControlLabel
         control={
           <ToggleSwitchButton
             checked={formik.values[fieldName]}
             onChange={(e) => formik.setFieldValue(fieldName, e.target.checked)}
             fieldName={fieldName}
             readOnlyField={isFieldDisabled(fieldName)}
           />
         }
        label={formik.values[fieldName] ? "Yes" : "No"}
      />
    </FormControl>
  );

   /**
   * This function is used to render date picker field
   * @param fieldName - Name of the field
   * @param label - Label of the field
   */
   const renderDatePicker = (
    fieldName: string = "",
    label: string = "",
  ) => (
    <FormControl style={{ margin: "5px 5px", width: "100%" }}>
      <label htmlFor={`outlined-${fieldName}`}>
        {label}
      </label>
      <TextField
        type="date"
        name={fieldName}
        value={moment(formik.values[fieldName]).format("YYYY-MM-DD")}
        onChange={(e) =>{
          const value = e.target.value;
          formik.setFieldValue(
            fieldName,
            value? moment(e.target.value, "YYYY-MM-DD").toDate(): null
          )
        }
        }
        onBlur={formik.handleBlur}
        style={{ marginTop: "5px", backgroundColor: getFieldBackgroundColor(fieldName) }}
        disabled={isFieldDisabled(fieldName)}
      />
    </FormControl>
  );

  /**
   * This function is used to handle Select dropdown value
   * @param fieldName - Name of the field
   * @param selectedItem - Selected option value
   */
  const handleDropDownOptionsChange = (fieldName, selectedItem) => {
    const selectedValue =
      typeof selectedItem === "string"
        ? selectedItem
        : REFERENCE_FIELDS.includes(fieldName)
          ? selectedItem.value
          : selectedItem.children;
    formik.setFieldValue(fieldName, selectedValue);

    // Check if the fieldName exists in FIELDS_WITH_DISPLAY_TEXT and set its mapped display field
    if (FIELDS_WITH_DISPLAY_TEXT.hasOwnProperty(fieldName)) {
      formik.setFieldValue(FIELDS_WITH_DISPLAY_TEXT[fieldName], selectedItem?.children);
    }

    if (fieldName === FIELD_NAMES.stage) {
      formik.setFieldValue(FIELD_NAMES.status, undefined);
      setDropDownFieldEnabled(FIELD_NAMES.status);
      prepareStatusDropDownOptions(selectedItem.value);
      prepareTagOptions(selectedItem.value, selectedTagOptions);
    }
    else if (fieldName === FIELD_NAMES.category) {
      formik.setFieldValue(FIELD_NAMES.function, undefined);
      setDropDownFieldEnabled(FIELD_NAMES.function);
      prepareFunctionDropDownOptions(selectedItem.value);
    }
  };

   /**
   * This function is used to returns list of DataTypes for a given field name
   * @param attributeName - Api response field name
   */
   const getAttributeValues = (attributeName: string) => {
    if(dropDownOptions && dropDownOptions.dataFieldsTypes) {
      return dropDownOptions.dataFieldsTypes
            ?.find(df => df.field.toLowerCase() === attributeName.toLowerCase())
            ?.types
            ?.map(t => {
              return {
                id: t.id,
                description: t.description,
              }
            });
    }
    else return [];
  };

  /**
   * This function is used to set status dropdown options based on stage field selection
   * @param stageId - Stage field api data
   */
  const prepareStatusDropDownOptions = (stageId: number) => {
    if (stageId && dropDownOptions?.stageStatusTags?.length) {
      const statusDropDownOptions = dropDownOptions.stageStatusTags
        .find(stage => stage.id == stageId)
        .statuses
        .map(s => {
          return {
            id: s.id,
            description: s.description,
          }
        });
      setStatusDropDownOptions(statusDropDownOptions);
    }
  };

  /**
   * This function is used to set function dropdown options based on category field selection
   * @param categoryName - Category display text field api data
   * @param dropDownItem - Selected dropdown value of Category field
   */
  const prepareFunctionDropDownOptions = (categoryId: number) => {
    if (categoryId && dropDownOptions?.productFunctions?.length) {
      const functionDropDownOptions = dropDownOptions.productFunctions
        .filter(func => func.category == categoryId)
        .map(item => {
          return {
            id: item.id,
            description: item.description
          }
        });
      setFunctionDropDownOptions(functionDropDownOptions);
    }
  };

  // List of fields with HTML renderer
  const formFieldsRenderer = {
    // START: New CVL Fields
    baseShape: (field) => () => renderAutocomplete(field.fieldName, field.label, getAttributeValues(dataFieldNames.baseShape)),
    batteryType: (field) => () => renderAutocomplete(field.fieldName, field.label, getAttributeValues(dataFieldNames.batteryType)),
    brand: (field) => () => renderAutocomplete(field.fieldName, field.label, getAttributeValues(dataFieldNames.brandCollection)),
    bulbShape: (field) => () => renderAutocomplete(field.fieldName, field.label, getAttributeValues(dataFieldNames.bulbShape)),
    bulbShapeCode: (field) => () => renderAutocomplete(field.fieldName, field.label, getAttributeValues(dataFieldNames.bulbShapeCode)),
    canopyShape: (field) => () => renderAutocomplete(field.fieldName, field.label, getAttributeValues(dataFieldNames.canopyShape)),
    category: (field) => () => renderDropDown(field.fieldName, field.label, dropDownOptions.productcategories),
    certiNote: (field) => () => renderAutocomplete(field.fieldName, field.label, getAttributeValues(dataFieldNames.certiNote)),
    colorTemperature: (field) => () => renderAutocomplete(field.fieldName, field.label, getAttributeValues(dataFieldNames.colorTemperature)),
    cri: (field) => () => renderAutocomplete(field.fieldName, field.label, getAttributeValues(dataFieldNames.cri)),
    department: (field) => () => renderAutocomplete(field.fieldName, field.label, getAttributeValues(dataFieldNames.department)),
    ledDimmingType: (field) => () => renderAutocomplete(field.fieldName, field.label, getAttributeValues(dataFieldNames.ledDimmingType)),
    driverLocation: (field) => () => renderAutocomplete(field.fieldName, field.label, getAttributeValues(dataFieldNames.driverLocation)),
    ledDriverType: (field) => () => renderAutocomplete(field.fieldName, field.label, getAttributeValues(dataFieldNames.ledDriverType)),
    ledType: (field) => () => renderAutocomplete(field.fieldName, field.label, getAttributeValues(dataFieldNames.ledType)),
    lightSource: (field) => () => renderAutocomplete(field.fieldName, field.label, getAttributeValues(dataFieldNames.lightSource)),
    locationRating: (field) => () => renderAutocomplete(field.fieldName, field.label, getAttributeValues(dataFieldNames.locationRating)),
    mountingType: (field) => () => renderAutocomplete(field.fieldName, field.label, getAttributeValues(dataFieldNames.mountingType)),
    reportingBrand: (field) => () => renderAutocomplete(field.fieldName, field.label, getAttributeValues(dataFieldNames.reportingBrand)),
    socketType: (field) => () => renderAutocomplete(field.fieldName, field.label, getAttributeValues(dataFieldNames.socketType)),
    wireColor: (field) => () => renderAutocomplete(field.fieldName, field.label, getAttributeValues(dataFieldNames.wireColor)),
    skuRank: (field) => () => renderAutocomplete(field.fieldName, field.label, getAttributeValues(dataFieldNames.sKURank)),
    bcRank: (field) => () => renderAutocomplete(field.fieldName, field.label, getAttributeValues(dataFieldNames.sKURank)),
    vendorMOQType: (field) => () => renderAutocomplete(field.fieldName, field.label, getAttributeValues(dataFieldNames.vendorMOQType)),
    itemStockingType: (field) => () => renderAutocomplete(field.fieldName, field.label, getAttributeValues(dataFieldNames.itemStockingType)),
    warehouse: (field) => () => renderAutocomplete(field.fieldName, field.label, getAttributeValues(dataFieldNames.warehouse)),
    slStatus: (field) => () => renderAutocomplete(field.fieldName, field.label, getAttributeValues(dataFieldNames.sLStatus)),
    primaryFinish: (field) => () => renderAutocomplete(field.fieldName, field.label, dropDownOptions.fixtureFinishes,
      (option) => `${option.code} - ${option.name}`),
    secondaryFinish: (field) => () => renderAutocomplete(field.fieldName, field.label, dropDownOptions.fixtureFinishes,
      (option) => `${option.code} - ${option.name}`),
    thirdFinish: (field) => () => renderAutocomplete(field.fieldName, field.label, dropDownOptions.fixtureFinishes,
      (option) => `${option.code} - ${option.name}`),
    developmentType: (field) => () => renderAutocomplete(field.fieldName, field.label, DEVELOPMENT_TYPE_DROPDOWN_OPTIONS),
    countryOfOrigin: (field) => () => renderAutocomplete(field.fieldName, field.label, getAttributeValues(dataFieldNames.countryOfOrigin)),
    // END: New CVL Fields
    certiType: (field) => () => renderAutocomplete(field.fieldName, field.label, getAttributeValues(dataFieldNames.certiType)),
    height: (field) => () => renderNumericField(field.fieldName, field.label),
    width: (field) => () => renderNumericField(field.fieldName, field.label),
    canopy: (field) => () => renderTextField(field.fieldName, field.label),
    fixture_Height: (field) => () => renderNumericField(field.fieldName, field.label),
    height_min: (field) => () => renderNumericField(field.fieldName, field.label),
    jBox: (field) => () => renderAutocomplete(field.fieldName,field.label, getAttributeValues(dataFieldNames.jBox)),
    oaHeight: (field) => () => renderTextField(field.fieldName, field.label),
    style: (field) => () => renderAutocomplete(field.fieldName,field.label, dropDownOptions.styles),
    romanticSize: (field) => () => renderAutocomplete(field.fieldName, field.label, getAttributeValues(dataFieldNames.romanticSize)),
    designGrouping: (field) => () => renderTextField(field.fieldName, field.label),
    stockcode: (field) => () => renderTextField(field.fieldName, field.label),
    longDesc: (field) => () => renderTextField(field.fieldName, field.label),
    pdFamilyName: (field) => () => renderTextField(field.fieldName, field.label),
    shortDesc: (field) => () => renderTextField(field.fieldName,field.label),
    // familyName: (field) => () => renderTextField(field.fieldName, field.label),
    function: (field) => () => renderDropDown(field.fieldName,field.label, functionDropDownOptions),    
    shortDescPrv: (field) => () => renderTextField(field.fieldName, field.label),
    pdCode: (field) => () => renderTextField(field.fieldName, field.label),
    stage: (field) => () => renderDropDown(field.fieldName, field.label, dropDownOptions.stageStatusTags),
    status: (field) => () => renderDropDown(field.fieldName, field.label, statusDropDownOptions),
    stockcodePrv: (field) => () => renderTextField(field.fieldName, field.label),
    basecodePrv: (field) => () => renderTextField(field.fieldName, field.label),
    royaltyDesigner: (field) => () => renderAutocomplete(field.fieldName, field.label, dropDownOptions.royaltyDesigners),
    marketingDesigner: (field) => () => renderAutocomplete(field.fieldName,field.label, dropDownOptions.productDesignerMkts),
    groupName: (field) => () => renderTextField(field.fieldName, field.label),
    socketQty: (field) => () => renderNumericField(field.fieldName, field.label),
    socketDtl: (field) => () => renderTextField(field.fieldName, field.label),
    ledLumens: (field) => () => renderTextField(field.fieldName, field.label),
    assembly: (field) => () => renderTextField(field.fieldName, field.label),
    mounting: (field) => () => renderAutocomplete(field.fieldName, field.label, getAttributeValues(dataFieldNames.mounting)),
    priMtrlManufMethod: (field) => () => renderAutocomplete(field.fieldName, field.label, dropDownOptions.maufacturingMethods),
    secMtrlManufMethod: (field) => () => renderAutocomplete(field.fieldName, field.label, dropDownOptions.maufacturingMethods),
    thirdMtrlManufMethod: (field) => () => renderAutocomplete(field.fieldName, field.label, dropDownOptions.maufacturingMethods),
    fourACDesc: (field) => () => renderTextField(field.fieldName, field.label),
    primaryMaterial: (field) => () => renderAutocomplete(field.fieldName, field.label, dropDownOptions.primaryMaterials),
    secondaryMaterial: (field) => () => renderAutocomplete(field.fieldName, field.label, dropDownOptions.secondaryMaterials),
    treatmentType: (field) => () => renderTextField(field.fieldName, field.label),
    treatment: (field) => () => renderTextField(field.fieldName, field.label),
    thirdMaterial: (field) => () => renderAutocomplete(field.fieldName, field.label, dropDownOptions.secondaryMaterials),
    skuType: (field) => () => renderAutocomplete(field.fieldName, field.label, dropDownOptions.skuTypes),
    shadeDesc: (field) => () => renderTextField(field.fieldName, field.label),
    wiringDtl: (field) => () => renderTextField(field.fieldName, field.label),
    candleTubeHeight: (field) => () => renderNumericField(field.fieldName, field.label),
    finialMaterial: (field) => () => renderTextField(field.fieldName, field.label),
    hardwareMaterial: (field) => () => renderAutocomplete(field.fieldName, field.label, dropDownOptions.primaryMaterials),
    rod_Count: (field) => () => renderNumericField(field.fieldName, field.label),
    rod_Diameter: (field) => () => renderTextField(field.fieldName, field.label),
    break_count: (field) => () => renderNumericField(field.fieldName, field.label),
    break_length: (field) => () => renderTextField(field.fieldName, field.label),
    rod_Shape: (field) => () => renderTextField(field.fieldName, field.label),
    knobType: (field) => () => renderAutocomplete(field.fieldName, field.label, getAttributeValues(dataFieldNames.knobType)),
    finialFinishCd: (field) => () => renderTextField(field.fieldName, field.label),
    baseMaterial: (field) => () => renderAutocomplete(field.fieldName, field.label, dropDownOptions.primaryMaterials),
    factoryItemNum: (field) => () => renderTextField(field.fieldName, field.label),
    factShadeGlassCode: (field) => () => renderTextField(field.fieldName, field.label),
    htsMaterial: (field) => () => renderAutocomplete(field.fieldName, field.label, dropDownOptions.htsMaterials),
    htsMaterialDtl: (field) => () => renderAutocomplete(field.fieldName, field.label, dropDownOptions.htsMaterialDetails),
    subContVendor1: (field) => () => renderAutocomplete(field.fieldName, field.label, dropDownOptions.productSuppliers),
    subContVendor1FactoryItem: (field) => () => renderTextField(field.fieldName, field.label),
    subContVendor2: (field) => () => renderAutocomplete(field.fieldName, field.label, dropDownOptions.productSuppliers),
    subContVendor2FactoryItem: (field) => () => renderTextField(field.fieldName, field.label),
    subContVendor3: (field) => () => renderAutocomplete(field.fieldName, field.label, dropDownOptions.productSuppliers),
    subContVendor3FactoryItem: (field) => () => renderTextField(field.fieldName, field.label),
    subContVendor4: (field) => () => renderAutocomplete(field.fieldName, field.label, dropDownOptions.productSuppliers),
    subContVendor4FactoryItem: (field) => () => renderTextField(field.fieldName, field.label),
    subContVendor5: (field) => () => renderAutocomplete(field.fieldName, field.label, dropDownOptions.productSuppliers),
    subContVendor5FactoryItem: (field) => () => renderTextField(field.fieldName, field.label),
    // New String Fields:
    allThreadType: (field) => () => renderTextField(field.fieldName, field.label),
    assemblyDetail: (field) => () => renderTextField(field.fieldName, field.label),
    backplateShape: (field) => () => renderTextField(field.fieldName, field.label),
    baseCode: (field) => () => renderTextFieldBaseCodeAuto(field.fieldName, field.label),
    basecodeDescription: (field) => () => renderTextField(field.fieldName, field.label),
    binning: (field) => () => renderTextField(field.fieldName, field.label),
    bladeFinish1: (field) => () => renderTextField(field.fieldName, field.label),
    bladeFinish2: (field) => () => renderTextField(field.fieldName, field.label),
    bulbInBox1: (field) => () => renderTextField(field.fieldName, field.label),
    bulbWattage: (field) => () => renderTextField(field.fieldName, field.label),
    candleTubeFinish: (field) => () => renderTextField(field.fieldName, field.label),
    cbCertificationNumber: (field) => () => renderTextField(field.fieldName, field.label),
    cccCertificationNumber: (field) => () => renderTextField(field.fieldName, field.label),
    ceCertificationNumber: (field) => () => renderTextField(field.fieldName, field.label),
    chainLength: (field) => () => renderTextField(field.fieldName, field.label),
    compatibleLightKit: (field) => () => renderTextField(field.fieldName, field.label),
    copyFreeForm: (field) => () => renderTextField(field.fieldName, field.label),
    cordColor: (field) => () => renderTextField(field.fieldName, field.label),
    cordCoverLength: (field) => () => renderTextField(field.fieldName, field.label),
    cordCoverTotalLength: (field) => () => renderTextField(field.fieldName, field.label),
    cordLength: (field) => () => renderTextField(field.fieldName, field.label),
    couplingWidth: (field) => () => renderNumericField(field.fieldName, field.label),
    criticalToQuality: (field) => () => renderTextField(field.fieldName, field.label),
    depth: (field) => () => renderTextField(field.fieldName, field.label),
    driverDimensions: (field) => () => renderTextField(field.fieldName, field.label),
    eacCertificateNumber: (field) => () => renderTextField(field.fieldName, field.label),
    emcCertificationNumber: (field) => () => renderTextField(field.fieldName, field.label),
    energyStarCertificationNumber: (field) => () => renderTextField(field.fieldName, field.label),
    etlCertificationNumber: (field) => () => renderTextField(field.fieldName, field.label),
    extension: (field) => () => renderTextField(field.fieldName, field.label),
    factoryFinishCode: (field) => () => renderTextField(field.fieldName, field.label),
    fcCertificationNumber: (field) => () => renderTextField(field.fieldName, field.label),
    feature1: (field) => () => renderTextField(field.fieldName, field.label),
    feature2: (field) => () => renderTextField(field.fieldName, field.label),
    feature3: (field) => () => renderTextField(field.fieldName, field.label),
    finialFinish: (field) => () => renderTextField(field.fieldName, field.label),
    hardwareFinish: (field) => () => renderTextField(field.fieldName, field.label),
    harpFinish: (field) => () => renderTextField(field.fieldName, field.label),
    harpType: (field) => () => renderTextField(field.fieldName, field.label),
    icCertificationNumber: (field) => () => renderTextField(field.fieldName, field.label),
    lampshadeType: (field) => () => renderTextField(field.fieldName, field.label),
    maxRotation: (field) => () => renderTextField(field.fieldName, field.label),
    maxSlopeCeiling: (field) => () => renderTextField(field.fieldName, field.label),
    mountingProcedure: (field) => () => renderTextField(field.fieldName, field.label),
    notesWeb: (field) => () => renderTextField(field.fieldName, field.label),
    packaging: (field) => () => renderTextField(field.fieldName, field.label),
    patentNumber: (field) => () => renderTextField(field.fieldName, field.label),
    pdStockcode: (field) => () => renderTextField(field.fieldName, field.label),
    itemClass: (field) => () => renderTextField(field.fieldName, field.label),
    profileRomanceCopy: (field) => () => renderTextField(field.fieldName, field.label),
    prop65: (field) => () => renderAutocomplete(field.fieldName, field.label, getAttributeValues(dataFieldNames.prop65)),
    recBulb1Hyperlink: (field) => () => renderTextField(field.fieldName, field.label),
    recBulb1ManufactureID: (field) => () => renderTextField(field.fieldName, field.label),
    recBulb1Name: (field) => () => renderTextField(field.fieldName, field.label),
    recBulb2Hyperlink: (field) => () => renderTextField(field.fieldName, field.label),
    recBulb2ManufactureID: (field) => () => renderTextField(field.fieldName, field.label),
    recBulb2Name: (field) => () => renderTextField(field.fieldName, field.label),
    relativeTrend: (field) => () => renderTextField(field.fieldName, field.label),
    remoteType: (field) => () => renderTextField(field.fieldName, field.label),
    riser: (field) => () => renderTextField(field.fieldName, field.label),
    rod1Length: (field) => () => renderNumericField(field.fieldName, field.label),
    rod2Length: (field) => () => renderNumericField(field.fieldName, field.label),
    rod3Length: (field) => () => renderNumericField(field.fieldName, field.label),
    seoCopy: (field) => () => renderTextField(field.fieldName, field.label),
    seriesDescription: (field) => () => renderTextField(field.fieldName, field.label),
    seriesName: (field) => () => renderTextField(field.fieldName, field.label),
    shadeExteriorMaterial: (field) => () => renderTextField(field.fieldName, field.label),
    shadeFitterType: (field) => () => renderTextField(field.fieldName, field.label),
    shadeInteriorMaterial: (field) => () => renderTextField(field.fieldName, field.label),
    shadeShape: (field) => () => renderTextField(field.fieldName, field.label),
    shadeSlant: (field) => () => renderNumericField(field.fieldName, field.label),
    standardCare: (field) => () => renderTextField(field.fieldName, field.label),
    switchDetails: (field) => () => renderTextField(field.fieldName, field.label),
    switchLocation: (field) => () => renderTextField(field.fieldName, field.label),
    tagFinishDescription: (field) => () => renderTextField(field.fieldName, field.label),
    title20Bulb: (field) => () => renderTextField(field.fieldName, field.label),
    transformerType: (field) => () => renderTextField(field.fieldName, field.label),
    treatmentTypeInterior: (field) => () => renderTextField(field.fieldName, field.label),
    vendor: (field) => () => renderAutocomplete(field.fieldName, field.label, dropDownOptions.productSuppliers),
    drawingNum: (field) => () => renderTextField(field.fieldName, field.label),
    sampleNotes: (field) => () => renderTextField(field.fieldName, field.label),
    bulbInBox2: (field) => () => renderTextField(field.fieldName, field.label),
    transformerMount: (field) => () => renderTextField(field.fieldName, field.label),
    reverseSwitch: (field) => () => renderTextField(field.fieldName, field.label),
    factoryPrimaryFinish: (field) => () => renderTextField(field.fieldName, field.label),
    factorySecondaryFinish: (field) => () => renderTextField(field.fieldName, field.label),
    factoryThirdFinish: (field) => () => renderTextField(field.fieldName, field.label),
    mfgSocketDetail: (field) => () => renderTextField(field.fieldName, field.label),
    // New Numeric fields
    averageBulbLife: (field) => () => renderNumericField(field.fieldName, field.label),
    backplateDepth: (field) => () => renderNumericField(field.fieldName, field.label),
    backplateHeight: (field) => () => renderNumericField(field.fieldName, field.label),
    backplateLength: (field) => () => renderNumericField(field.fieldName, field.label),
    backplateWidth: (field) => () => renderNumericField(field.fieldName, field.label),
    baseDepth: (field) => () => renderNumericField(field.fieldName, field.label),
    baseHeight: (field) => () => renderNumericField(field.fieldName, field.label),
    baseLength: (field) => () => renderNumericField(field.fieldName, field.label),
    baseWidth: (field) => () => renderNumericField(field.fieldName, field.label),
    bladePitch: (field) => () => renderNumericField(field.fieldName, field.label),
    bladeToCeilingMaxHeight: (field) => () => renderNumericField(field.fieldName, field.label),
    bladeToCeilingMinHeight: (field) => () => renderNumericField(field.fieldName, field.label),
    bulbBeamSpread: (field) => () => renderNumericField(field.fieldName, field.label),
    bulbDimmingRangeMax: (field) => () => renderNumericField(field.fieldName, field.label),
    bulbDimmingRangeMin: (field) => () => renderNumericField(field.fieldName, field.label),
    cableLength: (field) => () => renderNumericField(field.fieldName, field.label),
    candleTubeDiameter: (field) => () => renderNumericField(field.fieldName, field.label),
    candleTubeShape: (field) => () => renderNumericField(field.fieldName, field.label),
    canopyDepth: (field) => () => renderNumericField(field.fieldName, field.label),
    canopyLength: (field) => () => renderNumericField(field.fieldName, field.label),
    canopyShade: (field) => () => renderNumericField(field.fieldName, field.label),
    capacitorValue: (field) => () => renderNumericField(field.fieldName, field.label),
    carton1DimensionalWeight: (field) => () => renderNumericField(field.fieldName, field.label),
    carton1Height: (field) => () => renderNumericField(field.fieldName, field.label),
    carton1Length: (field) => () => renderNumericField(field.fieldName, field.label),
    carton1Weight: (field) => () => renderNumericField(field.fieldName, field.label),
    carton1Width: (field) => () => renderNumericField(field.fieldName, field.label),
    carton2DimensionalWeight: (field) => () => renderNumericField(field.fieldName, field.label),
    carton2Height: (field) => () => renderNumericField(field.fieldName, field.label),
    carton2Length: (field) => () => renderNumericField(field.fieldName, field.label),
    carton2Weight: (field) => () => renderNumericField(field.fieldName, field.label),
    carton2Width: (field) => () => renderNumericField(field.fieldName, field.label),
    carton3Height: (field) => () => renderNumericField(field.fieldName, field.label),
    carton3Length: (field) => () => renderNumericField(field.fieldName, field.label),
    carton3Weight: (field) => () => renderNumericField(field.fieldName, field.label),
    carton3Width: (field) => () => renderNumericField(field.fieldName, field.label),
    cartonCount: (field) => () => renderNumericField(field.fieldName, field.label),
    cfmAverage: (field) => () => renderNumericField(field.fieldName, field.label),
    cfmHigh: (field) => () => renderNumericField(field.fieldName, field.label),
    cfmLow: (field) => () => renderNumericField(field.fieldName, field.label),
    cfmMedium: (field) => () => renderNumericField(field.fieldName, field.label),
    cfmWattageHigh: (field) => () => renderNumericField(field.fieldName, field.label),
    cfmWattageLow: (field) => () => renderNumericField(field.fieldName, field.label),
    cfmWattageMedium: (field) => () => renderNumericField(field.fieldName, field.label),
    chainLink1Height: (field) => () => renderNumericField(field.fieldName, field.label),
    chainLink1Thickness: (field) => () => renderNumericField(field.fieldName, field.label),
    chainLink1Width: (field) => () => renderNumericField(field.fieldName, field.label),
    chainLink2Height: (field) => () => renderNumericField(field.fieldName, field.label),
    chainLink2Thickness: (field) => () => renderNumericField(field.fieldName, field.label),
    chainLink2Width: (field) => () => renderNumericField(field.fieldName, field.label),
    class: (field) => () => renderNumericField(field.fieldName, field.label),
    cordCoverBreakLength: (field) => () => renderNumericField(field.fieldName, field.label),
    cordCoverBreakWidth: (field) => () => renderNumericField(field.fieldName, field.label),
    cordCoverQty: (field) => () => renderNumericField(field.fieldName, field.label),
    couplingLength: (field) => () => renderNumericField(field.fieldName, field.label),
    cutoutSize: (field) => () => renderNumericField(field.fieldName, field.label),
    deliveredLumens: (field) => () => renderNumericField(field.fieldName, field.label),
    diameter: (field) => () => renderNumericField(field.fieldName, field.label),
    dnPrice: (field) => () => renderNumericField(field.fieldName, field.label),
    efficacy: (field) => () => renderNumericField(field.fieldName, field.label),
    fanDiameter: (field) => () => renderNumericField(field.fieldName, field.label),
    finialHeight: (field) => () => renderNumericField(field.fieldName, field.label),
    finialShape: (field) => () => renderNumericField(field.fieldName, field.label),
    finialWidth: (field) => () => renderNumericField(field.fieldName, field.label),
    heightFromCenterOfJbox: (field) => () => renderNumericField(field.fieldName, field.label),
    heightWithoutTail: (field) => () => renderNumericField(field.fieldName, field.label),
    hoursLifeOnHighPower: (field) => () => renderNumericField(field.fieldName, field.label),
    hoursLifeOnLowPower: (field) => () => renderNumericField(field.fieldName, field.label),
    hoursLifeOnMedPower: (field) => () => renderNumericField(field.fieldName, field.label),
    imap: (field) => () => renderNumericField(field.fieldName, field.label),
    installableHeight1: (field) => () => renderNumericField(field.fieldName, field.label),
    installableHeight2: (field) => () => renderNumericField(field.fieldName, field.label),
    installableHeight3: (field) => () => renderNumericField(field.fieldName, field.label),
    installableHeight4: (field) => () => renderNumericField(field.fieldName, field.label),
    installableHeight5: (field) => () => renderNumericField(field.fieldName, field.label),
    ipCube: (field) => () => renderNumericField(field.fieldName, field.label),
    ipHeight: (field) => () => renderNumericField(field.fieldName, field.label),
    ipLength: (field) => () => renderNumericField(field.fieldName, field.label),
    ipWeight: (field) => () => renderNumericField(field.fieldName, field.label),
    ipWidth: (field) => () => renderNumericField(field.fieldName, field.label),
    lampSKUNetWeight: (field) => () => renderNumericField(field.fieldName, field.label),
    ledBoardLumen: (field) => () => renderNumericField(field.fieldName, field.label),
    ledBoardWattage: (field) => () => renderNumericField(field.fieldName, field.label),
    length: (field) => () => renderNumericField(field.fieldName, field.label),
    lightQty: (field) => () => renderNumericField(field.fieldName, field.label),
    maxDegree: (field) => () => renderNumericField(field.fieldName, field.label),
    maxWattage: (field) => () => renderNumericField(field.fieldName, field.label),
    maximumHeight: (field) => () => renderNumericField(field.fieldName, field.label),
    minimumCustomHeight: (field) => () => renderNumericField(field.fieldName, field.label),
    minimumHeight: (field) => () => renderNumericField(field.fieldName, field.label),
    motorSize: (field) => () => renderNumericField(field.fieldName, field.label),
    mountingHoleQty: (field) => () => renderNumericField(field.fieldName, field.label),
    mpCube: (field) => () => renderNumericField(field.fieldName, field.label),
    mpHeight: (field) => () => renderNumericField(field.fieldName, field.label),
    mpLength: (field) => () => renderNumericField(field.fieldName, field.label),
    mpWidth: (field) => () => renderNumericField(field.fieldName, field.label),
    mpWeight: (field) => () => renderNumericField(field.fieldName, field.label),
    numberOfBlades: (field) => () => renderNumericField(field.fieldName, field.label),
    numberOfSockets: (field) => () => renderNumericField(field.fieldName, field.label),
    packQty: (field) => () => renderNumericField(field.fieldName, field.label),
    qtyFabricGlassShades: (field) => () => renderNumericField(field.fieldName, field.label),
    rod1Qty: (field) => () => renderNumericField(field.fieldName, field.label),
    rod2Qty: (field) => () => renderNumericField(field.fieldName, field.label),
    rod3Qty: (field) => () => renderNumericField(field.fieldName, field.label),
    rodTotalQty: (field) => () => renderNumericField(field.fieldName, field.label),
    shadeBottomDepth: (field) => () => renderNumericField(field.fieldName, field.label),
    shadeBottomWidth: (field) => () => renderNumericField(field.fieldName, field.label),
    shadeHeight: (field) => () => renderNumericField(field.fieldName, field.label),
    shadeQty: (field) => () => renderNumericField(field.fieldName, field.label),
    shadeTopDepth: (field) => () => renderNumericField(field.fieldName, field.label),
    shadeTopWidth: (field) => () => renderNumericField(field.fieldName, field.label),
    slNetWeight: (field) => () => renderNumericField(field.fieldName, field.label),
    standardEnergyUseWave: (field) => () => renderNumericField(field.fieldName, field.label),
    standardEstimatedYearlyEnergyCost: (field) => () => renderNumericField(field.fieldName, field.label),
    standardWeightedAverageAirflow: (field) => () => renderNumericField(field.fieldName, field.label),
    itemVendorCost: (field) => () => renderNumericField(field.fieldName, field.label),
    wattsHigh: (field) => () => renderNumericField(field.fieldName, field.label),
    wattsLow: (field) => () => renderNumericField(field.fieldName, field.label),
    wattsMedium: (field) => () => renderNumericField(field.fieldName, field.label),
    widthMax: (field) => () => renderNumericField(field.fieldName, field.label),
    widthMin: (field) => () => renderNumericField(field.fieldName, field.label),
    wireLength: (field) => () => renderNumericField(field.fieldName, field.label),
    vendorMOQ: (field) => () => renderNumericField(field.fieldName, field.label),
    vendorLeadTime: (field) => () => renderNumericField(field.fieldName, field.label),
    sampleQty: (field) => () => renderNumericField(field.fieldName, field.label),
    targetWholesalePrice: (field) => () => renderNumericField(field.fieldName, field.label),
    targetIMAP: (field) => () => renderNumericField(field.fieldName, field.label),
    targetFOB: (field) => () => renderNumericField(field.fieldName, field.label),
    canopyHeight: (field) => () => renderNumericField(field.fieldName, field.label),
    canopyWidth: (field) => () => renderNumericField(field.fieldName, field.label),
    // New boolean fields
    fieldModify: (field) => () => renderToggleButton(field.fieldName, field.label),
    hangStraight: (field) => () => renderToggleButton(field.fieldName, field.label),
    canBeInverted: (field) => () => renderToggleButton(field.fieldName, field.label),
    iS_220V: (field) => () => renderToggleButton(field.fieldName, field.label),
    iS_220VNotAvailable: (field) => () => renderToggleButton(field.fieldName, field.label),
    ada: (field) => () => renderToggleButton(field.fieldName, field.label),
    adjustable: (field) => () => renderToggleButton(field.fieldName, field.label),
    adjustableArm: (field) => () => renderToggleButton(field.fieldName, field.label),
    articulatingShadeHead: (field) => () => renderToggleButton(field.fieldName, field.label),
    assembledInTheUSA: (field) => () => renderToggleButton(field.fieldName, field.label),
    assembleRequired: (field) => () => renderToggleButton(field.fieldName, field.label),
    bulbIncluded: (field) => () => renderToggleButton(field.fieldName, field.label),
    cannotShipToCalifornia: (field) => () => renderToggleButton(field.fieldName, field.label),
    cannotShipToCanada: (field) => () => renderToggleButton(field.fieldName, field.label),
    cccCertification: (field) => () => renderToggleButton(field.fieldName, field.label),
    convertible: (field) => () => renderToggleButton(field.fieldName, field.label),
    convertibleKitRequired: (field) => () => renderToggleButton(field.fieldName, field.label),
    darkSky: (field) => () => renderToggleButton(field.fieldName, field.label),
    diffuser: (field) => () => renderToggleButton(field.fieldName, field.label),
    dwpIncluded: (field) => () => renderToggleButton(field.fieldName, field.label),
    emcCertification: (field) => () => renderToggleButton(field.fieldName, field.label),
    energyStar: (field) => () => renderToggleButton(field.fieldName, field.label),
    euCertification: (field) => () => renderToggleButton(field.fieldName, field.label),
    extendable: (field) => () => renderToggleButton(field.fieldName, field.label),
    fcCertification: (field) => () => renderToggleButton(field.fieldName, field.label),
    fwcCompliant: (field) => () => renderToggleButton(field.fieldName, field.label),
    globalAssortmentAvailable: (field) => () => renderToggleButton(field.fieldName, field.label),
    hasCordCover: (field) => () => renderToggleButton(field.fieldName, field.label),
    hasFanMount: (field) => () => renderToggleButton(field.fieldName, field.label),
    icCertification: (field) => () => renderToggleButton(field.fieldName, field.label),
    indicatorLight: (field) => () => renderToggleButton(field.fieldName, field.label),
    ipRating: (field) => () => renderToggleButton(field.fieldName, field.label),
    lightKitAdaptable: (field) => () => renderToggleButton(field.fieldName, field.label),
    madeInTheUSA: (field) => () => renderToggleButton(field.fieldName, field.label),
    madeToOrder: (field) => () => renderToggleButton(field.fieldName, field.label),
    motionSensorIncluded: (field) => () => renderToggleButton(field.fieldName, field.label),
    mountWallCeiling: (field) => () => renderToggleButton(field.fieldName, field.label),
    mountingPlate: (field) => () => renderToggleButton(field.fieldName, field.label),
    photocellIncluded: (field) => () => renderToggleButton(field.fieldName, field.label),
    pivotable: (field) => () => renderToggleButton(field.fieldName, field.label),
    preLacedChain: (field) => () => renderToggleButton(field.fieldName, field.label),
    remoteIncluded: (field) => () => renderToggleButton(field.fieldName, field.label),
    replaceableLamp: (field) => () => renderToggleButton(field.fieldName, field.label),
    rod1Cuttable: (field) => () => renderToggleButton(field.fieldName, field.label),
    rod1Removeable: (field) => () => renderToggleButton(field.fieldName, field.label),
    rod2Cuttable: (field) => () => renderToggleButton(field.fieldName, field.label),
    rod2Removeable: (field) => () => renderToggleButton(field.fieldName, field.label),
    rod3Cuttable: (field) => () => renderToggleButton(field.fieldName, field.label),
    rod3Removeable: (field) => () => renderToggleButton(field.fieldName, field.label),
    shipByTruck: (field) => () => renderToggleButton(field.fieldName, field.label),
    smartEnabled: (field) => () => renderToggleButton(field.fieldName, field.label),
    title24: (field) => () => renderToggleButton(field.fieldName, field.label),
    voltageConvertible: (field) => () => renderToggleButton(field.fieldName, field.label),
    warmColorDimming: (field) => () => renderToggleButton(field.fieldName, field.label),
    title20: (field) => () => renderToggleButton(field.fieldName, field.label),
    testing: (field) => () => renderToggleButton(field.fieldName, field.label),
    commission: (field) => () => renderToggleButton(field.fieldName, field.label),
    inventoryItem: (field) => () => renderToggleButton(field.fieldName, field.label),
    sellingItem: (field) => () => renderToggleButton(field.fieldName, field.label),
    purchasingItem: (field) => () => renderToggleButton(field.fieldName, field.label),
    etlCertification: (field) => () => renderToggleButton(field.fieldName, field.label),
    royalty: (field) => () => renderToggleButton(field.fieldName, field.label),
    // New Date-time fields
    sellingLineInStockDate: (field) => () => renderDatePicker(field.fieldName, field.label),
    slIntroDate: (field) => () => renderDatePicker(field.fieldName, field.label),
    agreedSampleReadyDate: (field) => () => renderDatePicker(field.fieldName, field.label),
    reqDate: (field) => () => renderDatePicker(field.fieldName, field.label),
    // BOM Category
    bomType: (field) => () => renderDropDown(field.fieldName, field.label, dropDownOptions.bomType),
    bomSearch: () => () => renderBOMAutocompleteSearch(),
    dataGridView: () => () => renderBOMGridView(),
    
    itemStatus: (field) => () => renderAutocomplete(field.fieldName, field.label, Object.entries(ITEM_STATUS_ENUM)
    .filter(([key, value]) => isNaN(Number(key)))
    .map(([key, value]) => {
        return { 
          "id": key, 
          "name":  value.replace(/([a-z])([A-Z])/g, "$1 $2")
        }
      })
    ),
  };

  const formRendererNames = Object.keys(formFieldsRenderer)
 
  const handleAddbutton= () => {
    let lastSquence = bomList.length > 0 ? bomList[bomList.length-1].sequenceNum: -1;
    
    let parseIntQty = parseInt(qty);
    // Check if both selectedItemId and qty are not null or empty
    if (selectedBOMItem && qty) {
      //validation
      if ( parseIntQty >= 999) {
        setBomQtyError(true);
        setBomErrorMessage('Quantity Cannot exceed 999');
        return;
      } else if(parseIntQty <= 0) {
        setBomQtyError(true);
        setBomErrorMessage('Quantity Cannot be 0');
        return;
      }
      else {
        setBomQtyError(false);
      }
      // Create a new temporary BOM item
      const newBOMItemItem: BomGridView = {
        componentItemId: selectedBOMItem.id,
        stockcode: selectedBOMItem.stockcode,
        longDesc: selectedBOMItem.longDesc,  
        sequenceNum: lastSquence +1,
        skuType: selectedBOMItem.skuType, 
        quantity: parseIntQty,
        fobCost: selectedBOMItem?.fobCost,
        ipCube: selectedBOMItem?.ipCube,
        dnPrice: selectedBOMItem?.dnPrice,
        umapPrice: selectedBOMItem?.umapPrice,
        grossGMPercent: selectedBOMItem?.grossGMPercent,
        vendorDisplayText: selectedBOMItem?.vendorDisplayText,
        quoteDate: selectedBOMItem?.quoteDate,
        quoteApprovalType: selectedBOMItem?.quoteApprovalType
      };
      
      // Add the new temporary BOM item to the temporary list
      setBomList([...bomList, newBOMItemItem]);
      
      // Clear bomSearchTerm fields after adding
      setSelectedBOMItem(null);
      setSearchTerm('');
      setQty('');
    } else {
      console.error("Please select a stock code and provide quantity.");
    }
  };
  
  const handleSelectedItem = (item: BomGridView | null) => {
    setSelectedBOMItem(item);
  };

  const handleAddBOMComponent = () => {
    setShowAddBOMComponentModal(true);
  };

  const handleBOMComponentCloseModal = () => {
    setShowAddBOMComponentModal(false);
  };

  const handleSyncSAPComponent = () => {
    api.post<ApiResponse<boolean>>("/component/sap-syncup", null)
      .then((response) => {
        if(response?.isSuccess) {
          toast.success(response?.message);
        }
        else {
          toast.error(response?.message);
        }
      })
      .catch((error) => {
        console.error("Exception from Sync SAP Component", error);
      });
  };
  
  const [validateOnBlur, setValidateOnBlur] = useState(false);
  const renderBOMAutocompleteSearch=() => (
    (sessionService.hasPermission(Permissions.ManageBOM) && formik.values.bomType) &&
    <>
      <Divider sx = {{marginTop: "10px"}}/>
      <FormControl style = {{marginTop: "12px"}}>
        <Box sx = {{display: "flex", flexDirection: "column"}}>
          <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
            <Typography>Add BOM</Typography>
            <Box sx={{ display: "flex" }}>
              <Button variant="contained" onClick={handleAddBOMComponent}>
                Add New Component
              </Button>
              <Button variant="contained" style={{ marginLeft: "5px" }} onClick={handleSyncSAPComponent}>
                Sync SAP Components
              </Button>
            </Box>
          </Box>
          <Box sx = {{display: "flex", flexDirection: "row", alignItems: "center", marginTop: "15px", gap: "10px" }}>
            <AutoCompleteStockCode
              bomList = {bomList}
              selectedItem = {handleSelectedItem}
              searchTerm={searchTerm}
              setSearchTerm={setSearchTerm}
            /> 
            <TextField
              label = {bomQtyError? bomErrorMessage : "Qty"}
              className="hide-input-arrow"
              inputProps={{ type: "number"}}
              onKeyDown={(e) => {
                (e.key === "ArrowUp" || e.key === "ArrowDown") && e.preventDefault()
                // Get the key value directly from event.key
                const charStr = e.key;
                // Allow only digits (0-9) and control keys like Backspace
                if (!/^\d$/.test(charStr) && e.key !== 'Backspace') {
                  e.preventDefault();
                  }
                }
              }
              onFocus={(e) => e.target.addEventListener("wheel", function (e) { e.preventDefault() }, { passive: false })}
              value={qty || ''}
              onChange = {(event) => {
                const value = event.target.value;
                if (validateOnBlur) {
                    const parsedQty = parseInt(value);
                    if (isNaN(parsedQty) || parsedQty <= 0 || parsedQty >= 999) {
                        setBomQtyError(true);
                        setBomErrorMessage(parsedQty >= 999 ? 'Quantity Cannot exceed 999' : 'Quantity Cannot be 0');
                    } else {
                        setBomQtyError(false);
                        setBomErrorMessage('');
                    }
                }
                setQty(value);
              }}
              onBlur={() => setValidateOnBlur(true)}
              error = {bomQtyError}
              />
            <Button variant="contained" onClick = {handleAddbutton}>
              Add
            </Button>
          </Box>
        </Box>
      </FormControl>
    </>
  );


  const handleDeleteBom = (params: any) => {
    const bomListAfterRemoval = bomList.filter(b => b.stockcode != params.stockcode);
    //reassign the sequence after delete
    bomListAfterRemoval.forEach((bom, index) => {
      bomListAfterRemoval[index].sequenceNum = index;
    });
    setBomList(bomListAfterRemoval);
  };

  /**
   * This function is used to render date picker field
   * @param fieldName - Name of the field
   * @param label - Label of the field
   */
  const renderValueWithSymbol = (fieldName, value) => {
    const symbol = QUOTE_FIELD_SYMBOLS[fieldName];
    const fieldValue = value || 0;

    const formattedValue = symbol
      ? symbol === "%" // Check for postfix fields
        ? `${fieldValue}${symbol}` // Append for postfix
        : `${symbol}${fieldValue}` // Prepend for prefix
      : fieldValue; // Default case with no symbol
  
    return formattedValue;
  };

  const BOMDataGridCollumns = useMemo<MRT_ColumnDef<BomGridView>[]>(() => [
    { accessorKey: "stockcode", header: "Stock Code", size: 100 },
    { accessorKey: "longDesc", header: "Description", size: 120 },
    { accessorKey: "sequenceNum", header: "Sequence Number", size: 100 },
    { accessorKey: "skuType", header: "SKU Type", size: 120 },
    { accessorKey: "quantity", header: "Qty", size: 100 },
    { accessorKey: "fobCost", header: "FOB Cost", size: 100, Cell: ({ row }) => {
      const fobCost = row.original.fobCost;
      return renderValueWithSymbol("fobCost", fobCost)
    } },
    { accessorKey: "ipCube", header: "IP Cube", size: 100 },
    { accessorKey: "dnPrice", header: "DN", size: 100, Cell: ({ row }) => {
      const dnPrice = row.original.dnPrice;
      return renderValueWithSymbol("dnPrice", dnPrice)
    } },
    { accessorKey: "umapPrice", header: "UMAP", size: 100, Cell: ({ row }) => {
      const umapPrice = row.original.umapPrice;
      return renderValueWithSymbol("umapPrice", umapPrice)
    } },
    { accessorKey: "grossGMPercent", header: "Gross GM Percent", size: 100, Cell: ({ row }) => {
      const grossGMPercent = row.original.grossGMPercent;
      return renderValueWithSymbol("grossGMPercent", grossGMPercent)
    } },
    { accessorKey: "vendorDisplayText", header: "Vendor", size: 100 },
    {
      accessorKey: "quoteDate", header: "Quote Date", size: 100, Cell: ({ row }) => {
        const quoteDate = row.original.quoteDate;
        return quoteDate ? moment(quoteDate).format(DATE_FORMAT) : ''
      }
    },
    {
      accessorKey: "quoteApprovalType", header: "Quote Approval Type", size: 100, Cell: ({ row }) => {
        const quoteApprovalType = row.original.quoteApprovalType;
        return QUOTE_APPROVAL_TYPE_DROPDOWN_OPTIONS.find((qa) => qa.id === quoteApprovalType)?.name || ''
      }
    },
  ], []);

  const table = useMaterialReactTable({
    columns: BOMDataGridCollumns,
    data: bomList,
    enablePagination: false,
    enableRowActions: !checkBOMPermission(),
    positionActionsColumn: !checkBOMPermission() ? "last" : null,
    enableRowOrdering: !checkBOMPermission(),
    enableSorting: false,
    enableFilters: false,
    enableCellActions: !checkBOMPermission(),
    enableColumnActions: false,
    renderRowActions: (({row}) => {
      if (checkBOMPermission()) return null;
      return (
        <IconButton onClick={() => handleDeleteBom(row.original)}>
          <Delete />
        </IconButton>
      )
    }),
    muiRowDragHandleProps: !checkBOMPermission() ?
     ({table}) => ({
      onDragEnd: async () => {
        const {draggingRow, hoveredRow} = table.getState();
        if(draggingRow && hoveredRow) {
          bomList.splice(
            (hoveredRow as MRT_Row<BomGridView>).index,0,
            bomList.splice(draggingRow.index, 1)[0]
          );
          setBomList([...bomList]);
          await updateSequenceNumber(bomList);
        }
      }
    }) : null
  });

  const updateSequenceNumber = async (reOrderedList: any) => {
    let sort = 0;
    reOrderedList.forEach((bom) => {
      bom.sequenceNum = sort;
      sort++;
    });
  }

  const renderBOMGridView = () => {
    return (
      <Box style={{ marginTop: '2%', width: '100%' }}>
        <MRT_TableContainer table={table} sx={{ scrollbarWidth: "thin" }} />
      </Box>
    )
  }

  /**
   * This function is used to filter attachment list and store attachment id in state to delete attachment(s)
   * @param index - Array index of attachment list
   */
  const handleRemoveAttachment = (index: number) => {
    const updatedAttachments = attachments.filter(
      (a, i) => i !== index
    );
    let removeId;

    attachments.filter(
      (a, i) => i === index && a.disabled === true
    ).map((item) => {
      return removeId = item.attachId
    });
    setAttachments(updatedAttachments);

    if (removeId) {
      setDeleteAttachmentIds([...deleteAttachmentIds, removeId]);
    }
  };

  /**
   * This function is used to handle remove selected tag
   * @param tagId - Tag Id
   */
  const handleRemoveTag = (tagId: number) => {
    // Remove tag from selected tag state
    const tagsAfterRemoval = selectedTagOptions.filter((st) => st.id !== tagId);
    // Add tag in tag menu options which is currently removed from selected tag
    const removedTagInfo = selectedTagOptions.find(t => t.id === tagId);
    const sortedTagMenuOptions = [...tagMenuOptions, removedTagInfo].sort((a, b) => a.id > b.id ? 1 : -1);
    setSelectedTagOptions(tagsAfterRemoval);
    setTagMenuOptions(sortedTagMenuOptions);    
  };

  /**
   * This function is used to handle tag option selection
   * @param tagToAdd - Selected Tag Option
   */
  const handleTagOptionSelect = (tagToAdd: Tag) => {
    // Add tag in selected tag option to display selected tags button
    setSelectedTagOptions([...selectedTagOptions, tagToAdd]);
    // Remove the selected tag from tag menus so that it cannot be selected again
    let updateTagOptions = tagMenuOptions.filter(t => t.id !== tagToAdd.id);
    setTagMenuOptions(updateTagOptions);
  };

  /**
   * This function is used to handle accordian group expand / collapse
   * @param group - Accordian group name
   */
  const handleAccordianExpand = (group: string) => {
    const isSectionOpen = accordianExpanded.includes(group);

    // Check if section is expanded (group name present in array) and also check required field contains value
    if (isSectionOpen && formik.values[FIELD_NAMES.brand] && formik.values[FIELD_NAMES.shortDesc]) {
      // Remove the group from the expanded array
      const updatedOpenSections = accordianExpanded.filter(sec => sec !== group);
      setAccordianExpanded(updatedOpenSections);
    } else {
      // Add the group to the expanded array
      setAccordianExpanded([...accordianExpanded, group]);

      // If the section is being expanded for the first time, mark it as rendered
      if (!sectionOpenHistory.includes(group)) {
        setSectionOpenHistory([...sectionOpenHistory, group]);
      }
    }
  };

  const handleBaseCodeModificationModalClose = () => {
    setShowBaseCodeModificationConfirmModal(false);
    setDisableSubmit(false);
  };

  const handleBaseCodeModificationConfirm = (baseCodeModificationConfirm: string) => {
    submitFormDataToItemApi(baseCodeModificationConfirm);
    handleBaseCodeModificationModalClose();
  };

  // This function is used to auto assign the project manager assignment with conditions
  const processAutoAssignments = () => {
    // find the user assignments that are currently assigned as project manager
    const matchAutoAssignmentWithExistingProductAssignment = autoAssignments?.find(a =>
      selectedUserAssignments?.some((ua) => ua.userId === a.userId)
    );

    if (matchAutoAssignmentWithExistingProductAssignment) {
      // find the existing selected user assignments with auto assignment list
      const matchingUserAssignment = selectedUserAssignments?.find(ua =>
        ua.userId === matchAutoAssignmentWithExistingProductAssignment?.userId
      );
      // Get the assignment name that already exists as Project Manager assignment from the assignment dropdown options
      const assignmentName = assignmentRoleDropdownOptions?.find(option =>
        option.id === matchingUserAssignment?.assignmentId
      )?.name;
      // Get the user name that already exists as Project Manager assignment from the user dropdown options
      const username = userDropdownOptions?.find(u => u.id === matchAutoAssignmentWithExistingProductAssignment?.userId)?.name;
      // Display error toast message when existing selected assignment and auto assignment contains the same user with it's assignment
      const errorMessage = `User ${username} is already assigned as ${assignmentName}. Cannot add the same user as Project Manager to this product`
      toast.error(errorMessage);
    }

    // find out the user assignments which are not already assigned in current product and also return with id = 0
    const autoAssignmentList = autoAssignments?.filter(a =>
      !selectedUserAssignments?.some((ua) => ua.userId === a.userId)
    ).map(a => ({
      ...a,
      id: 0
    }));

    if (autoAssignmentList) {
      // remove the default blank assignment
      const removeEmptyUserAssignment = selectedUserAssignments?.filter(
        assignment => assignment.userId !== ""
      );
      setSelectedUserAssignments([...removeEmptyUserAssignment, ...autoAssignmentList]);
    }
    setShowBaseCodeModificationConfirmModal(true);
  };

  // This function is used to handle auto assignment confirmation
  const handleAutoAssignmentModalConfirm = (autoAssignConfirm: string) => {
    if (autoAssignConfirm === "Yes") {
      processAutoAssignments();
      setShowAutoAssignmentConfirmModal(false);
    }
    else {
      setShowAutoAssignmentConfirmModal(false);
      setShowBaseCodeModificationConfirmModal(true);
    }
  };

  const handleAutoAssignmentModalClose = () => {
    setShowAutoAssignmentConfirmModal(false);
    setDisableSubmit(false);
  };

  const addCommentSubmitToApi = async (newComment: NewComment) => {
    const { comment, isBaseCodeChecked } = newComment;

    const addNewCommentResponse = await api.post<ApiResponse<number>>(
      `/ProductComment/add`,
      {
        productId: editItemId,
        comment: comment,
        commentType: "General",
        baseCode: isBaseCodeChecked ? editItem.baseCode : null,
        pdBaseCode: isBaseCodeChecked ?  editItem.pdCode : null
      });
    if (addNewCommentResponse?.isSuccess) {
      fetchProductCommentList();
    }
  };

  const updateCommentSubmitToApi = async (updatedComment: NewComment) => {
    const { id, comment, isBaseCodeChecked } = updatedComment;

    const updateCommentResponse = await api.put<ApiResponse<boolean>>(
      `/ProductComment/edit/${id}`,
      {
        id: id,
        comment: comment,
        baseCode: isBaseCodeChecked ? editItem.baseCode : null,
        pdBaseCode: isBaseCodeChecked ?  editItem.pdCode : null
      });
    if (updateCommentResponse?.isSuccess) {
      fetchProductCommentList();
    }
  };

  const handleCommentFormSubmit = async (newComment: NewComment) => {
    // This condition is to check if user has added a new comment to submit
    if(newComment.id === 0) {
      addCommentSubmitToApi(newComment);
    }
    // else user has updated existing comment from the list
    else {
      updateCommentSubmitToApi(newComment);
    }
  };

  const handleDeleteComment = async (commmentId: number) => {
    const deleteCommentResponse = await api.delete<ApiResponse<boolean>>(
      `/ProductComment/delete/${commmentId}`);
    if (deleteCommentResponse?.isSuccess) {
      fetchProductCommentList();
    }
  };

  /**
   * This function is used to render accordian based on grouped fields. each groups accordian containg some fields
   * @param group - Accordian group name
   * @param fields - Accordian field name of groups
   */
  const renderFormSection = (
    group: string,
    fields: ProductLayoutFieldDetail[]) => {

    const shouldRenderFields = accordianExpanded.includes(group) || sectionOpenHistory.includes(group);

    return (
      <Accordion
        key={group}
        expanded={accordianExpanded.includes(group)}
        onChange={() => handleAccordianExpand(group)}
      >
        <AccordionSummary
          expandIcon={<ArrowDropDownIcon />}
          aria-controls={`panel-${group}-content`}
          id={`panel-${group}-header`}
          className={accordianExpanded.includes(group) && "active-accordian-expanded"}
        >
          {accordianExpanded.includes(group) ? <RemoveIcon /> : <AddIcon />}
          <Typography style={{ fontWeight: accordianExpanded.includes(group) ? "bold" : "normal" }}>
            {group}
          </Typography>
        </AccordionSummary>
        <AccordionDetails className="accordian-input-fields">
          {shouldRenderFields &&
            fields.sort((a, b) => a.tabOrder - b.tabOrder).map(field => {
              const renderFunction = formFieldsRenderer[formRendererNames.find((fieldName) => fieldName.toLowerCase() === field.fieldName.toLowerCase())]; // Use the camelCase version of the field name
              return renderFunction ? renderFunction(field)() : null;
            })}
        </AccordionDetails>
      </Accordion>
    );
  };

  const checkBaseCodeExist = Boolean(formik.values.baseCode);

  const handleDeleteModal = () => {
    setDeleteModal(true);
  };

  const floatingButton = () => {
    setShowButtons(!showButtons);
  };

  const deleteSubmit = async () => {
    try{
      api
        .delete<ApiResponse<boolean>>(`/Item/${editItemId}`)
        .then( async (response:any) => {
          if(response.isSuccess){
            toast.success(response.message);
            setDeleteModal(false);
            navigate("/products")
          }
        })
        .catch(() => {});
    }catch(error: any) {
      toast.error(error.message);
    }
  };

  const setProductIPCube = () => {
    return (formik.values.ipCube || null);
  };

  // This function is used to close the Quote Details Comparison Modal
  const onIPCubeUpdatesModalClose = () => {
    setIPCubeUpdatesModalOpen(false);
  };

  // This variable to used to display Name of the User in Auto Assignment confirmation dialog
  const autoAssignmentUsername = userDropdownOptions?.find(u => 
    u.id === autoAssignments?.find(a =>
      !selectedUserAssignments?.some((ua) => ua.userId === a.userId)
    )?.userId
  )?.name;

  return (
    <>
      <DeleteValidationModal 
        addModalOpen={deleteModal} 
        handleCloseModal={handleCloseModal} 
        deleteSubmit={deleteSubmit} 
        fieldIdentifier={`Product #${editItemId}`}
      />
      {ipCubeUpdatesModalOpen && (
        <QuoteDetailsComparisonModal
          open={ipCubeUpdatesModalOpen}
          onClose={onIPCubeUpdatesModalClose}
          oldValues={oldQuoteDetailsForIPCubeUpdates}
          newValues={newQuoteDetailsForIPCubeUpdates}
        />)}
      {<ConfirmationModal
        isOpen={showConfirmModal}
        onClose={handleCloseModal}
        title={"Confirm BaseCode Auto Generate"}
        message={"Are you sure you want to regenerate Base Code for this product?"}
        handleConfirmYes={handleBaseCodeAuto}
        handleConfirmNo={handleCloseModal}
      />}
      <ConfirmationModal
        isOpen={showBaseCodeModificationConfirmModal}
        onClose={handleBaseCodeModificationModalClose}
        title={"Confirm BaseCode Modification"}
        titleForConfirmYesButton={"Yes, Change All"}
        titleForConfirmNoButton={"No, Change Current Product Only"}
        message={<>
          You modified BaseCode From {editItem?.baseCode} TO {formik.values[FIELD_NAMES.baseCode]}.
          <br /> Do you want to update the new BaseCode for all Products having current BaseCode value {editItem?.baseCode}?
        </>}
        handleConfirmYes={() => handleBaseCodeModificationConfirm("Yes")}
        handleConfirmNo={() => handleBaseCodeModificationConfirm("No")}
      />
      <ConfirmationModal
        isOpen={showAutoAssignmentConfirmModal}
        onClose={handleAutoAssignmentModalClose}
        title={"Confirm Auto Assignment"}
        titleForConfirmYesButton={"Yes"}
        titleForConfirmNoButton={"No"}
        message={<>
          Do you want to Assign the <b>{autoAssignmentUsername}</b> as <b>Project Manager</b> Assignment to this Product for the modified BaseCode <b>{formik.values[FIELD_NAMES.baseCode]}</b>?
        </>}
        handleConfirmYes={() => handleAutoAssignmentModalConfirm("Yes")}
        handleConfirmNo={() => handleAutoAssignmentModalConfirm("No")}
      />
      <BOMComponentModal
        isOpen={showAddBOMComponentModal}
        onClose={handleBOMComponentCloseModal}
        isButtonDisabled={disableSubmit}
        layoutFields={productLayoutFields}
        dropDownOptions={dropDownOptions} 
        />
      <div style={{ display: "flex" }}>
      <Box
        className="edit-item-main-container"
        style={{ width: pageQueryParams ? "80%" : "100%"}}
      >
        <Card sx={{ marginBottom: 1 }} className="accordian-container">
          <CardContent>
            <div style={{ display: "flex" }}>
              {editItem &&
                <span
                  className="title-text"
                >
                  {editItem.shortDesc && `${editItem.shortDesc} - `}
                  {editItem.stockcode ? editItem.stockcode : `Product Id ${editItem.id}`}
                </span>}
              <span style={{ marginLeft: "auto", cursor: "pointer" }}>
                <CloseIcon onClick={()=> navigate("/products")} />
              </span>
            </div>
            {(sessionService.hasPermission(Permissions.CreateProduct) || 
              sessionService.hasPermission(Permissions.UpdateProduct) || 
              sessionService.hasPermission(Permissions.ViewProducts) ||
              sessionService.hasPermission(Permissions.ManageTags)) && 
              Object.keys(dropDownOptions).length > 0 && (
                <Tags
                  selectedTagOptions={selectedTagOptions}
                  tagDropDownOptions={tagMenuOptions}
                  handleRemoveTag={handleRemoveTag}
                  handleTagOptionSelect={handleTagOptionSelect}
                  uniqueTagsIdList = {uniqueTagsIdList}
                />
              )}
              <form onSubmit={formik.handleSubmit}>
                <div
                  style={{position:"fixed",
                    zIndex: 1000,
                    right: "22%",
                    bottom: "5%",
                    
                  }}
                >
                  {(sessionService.hasPermission(Permissions.ManageAttachments) ||
                    sessionService.hasPermission(Permissions.ManageProductAssignments) ||
                    sessionService.hasPermission(Permissions.ManageTags) ||
                    sessionService.hasPermission(Permissions.UpdateProduct) ||
                    sessionService.hasPermission(Permissions.DeleteProject) ||
                    assignmentEditableFields?.length > 0) && showFab 
                    && <Fab
                    variant="circular" 
                    size="medium"
                    onClick = {floatingButton}
                  >
                    <AddIcon />
                  </Fab>}
                  {showButtons &&
                    <>
                      {sessionService.hasPermission(Permissions.DeleteProject) && showFab &&
                        <Fab
                          variant="extended"
                          size="medium"
                          sx={{ padding: "15px", margin: "10px" }}
                          onClick={handleDeleteModal}
                          disabled={disableSubmit}
                        >
                          <Delete />
                          Delete
                        </Fab>}
                      {(sessionService.hasPermission(Permissions.ManageAttachments) ||
                        sessionService.hasPermission(Permissions.ManageProductAssignments) ||
                        sessionService.hasPermission(Permissions.ManageTags) ||
                        sessionService.hasPermission(Permissions.UpdateProduct) ||
                        assignmentEditableFields?.length > 0) && showFab &&
                        <Fab
                          variant="extended"
                          size="medium"
                          sx={{ padding: "10px" }}
                          type="submit"
                          disabled={disableSubmit}
                        >
                          Submit
                        </Fab>}
                    </>}
                </div>
                <div style={{ paddingBottom: "1px" }}>
                  {(sessionService.hasPermission(Permissions.ManageAttachments) ||
                    sessionService.hasPermission(Permissions.ManageProductAssignments) ||
                    sessionService.hasPermission(Permissions.ManageTags) ||
                    sessionService.hasPermission(Permissions.UpdateProduct) ||
                    assignmentEditableFields?.length > 0)
                    && <Button
                      type="submit"
                      variant="contained"
                      style={{ float: "right" }}
                      disabled={disableSubmit}
                    >
                      Submit
                    </Button>}
                    {sessionService.hasPermission(Permissions.DeleteProject)
                    && <Button
                      variant="contained"
                      style={{ float: "right", marginRight: '10px'}}
                      disabled={disableSubmit}
                      onClick={handleDeleteModal}
                    >
                      <Delete />
                       Delete
                    </Button>}
                </div>
                <div style={{ marginTop: "50px" }}>
                  <StageCheckList
                    checkListStatus={checkListStatus}
                    setCheckListStatus={setCheckListStatus}
                  />
                  {productLayoutFields?.length > 0 &&
                    productLayoutFields
                      .sort((a, b) => a.tabOrder - b.tabOrder)
                      .map((lf) => renderFormSection(lf.categoryName, lf.fields))}
                </div>
                <AcceptedQuote
                  productDetail={formik.values}
                  productId={Number(editItemId)}
                  setSelectedQuote={setSelectedQuote}
                  selectedQuote={selectedQuote}
                  setSelectedVendorForQuote={setSelectedVendorForQuote}
                  getProductIPCube={setProductIPCube}
                  setIPCubeUpdatesModalOpen={setIPCubeUpdatesModalOpen}
                  setOldQuoteDetailsForIPCubeUpdates={setOldQuoteDetailsForIPCubeUpdates}
                  setNewQuoteDetailsForIPCubeUpdates={setNewQuoteDetailsForIPCubeUpdates}
                />
                {(sessionService.hasPermission(Permissions.CreateProduct) ||
                  sessionService.hasPermission(Permissions.UpdateProduct) ||
                  sessionService.hasPermission(Permissions.ViewProducts) ||
                  sessionService.hasPermission(Permissions.ManageProductAssignments)) &&
                  <UserAssignments
                    selectedUserAssignments={selectedUserAssignments}
                    setSelectedUserAssignments={setSelectedUserAssignments}
                    viewMode="EditProduct"
                    assignmentRoleDropdownOptions={assignmentRoleDropdownOptions}
                    userDropdownOptions={userDropdownOptions}
                  />}
                {(sessionService.hasPermission(Permissions.CreateProduct) ||
                  sessionService.hasPermission(Permissions.UpdateProduct) ||
                  sessionService.hasPermission(Permissions.ViewProducts) ||
                  sessionService.hasPermission(Permissions.ManageAttachments)) &&
                  <div style={{ marginTop: "20px" }}>
                    {attachments.length > 0 || sessionService.hasPermission(Permissions.ManageAttachments) ?
                      <AttachmentsUploader
                        title="Upload pdf, doc, docx, jpeg, png, tiff, ai, zip"
                        onRemove={handleRemoveAttachment}
                        attachments={attachments}
                        setAttachments={setAttachments}
                        attachmentTypeDropdownOptions={dropDownOptions.attachmentTypes}
                        setShowFab = {setShowFab}
                        setDisableSubmit={setDisableSubmit}
                        ref={uploaderRef}
                      /> : null}
                  </div>}
              </form>
          </CardContent>
        </Card>
      </Box>
        {pageQueryParams &&
          <EditProductGridView editItemId={editItemId} />}
      </div>
      <ProductComments
        commentList={commentList}
        onSubmit={handleCommentFormSubmit}
        onDelete={handleDeleteComment}
        editItem={editItem}
      />
    </>
  );
};
export default EditProduct;
