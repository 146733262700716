import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SortDirection } from "../models/Enum";
import { ToolingListRequest } from "../models/Tooling/ToolingListRequest";
import { SelectedFilters } from "../models/Items/ItemListRequest";

type FiltersState = {
  selectedFilterOptions: any;
  selectedFilterValues: SelectedFilters;
}

interface ToolingsState {
    toolingListRequest: ToolingListRequest;
    toolingListFilter: FiltersState;
};

const initialToolingListRequest = {
    pageIndex: 1,
    pageSize: 10,
    sortBy: "id",
    sortDirection: SortDirection.DESC,
};

const initialState: ToolingsState = {
    toolingListRequest: initialToolingListRequest,
    toolingListFilter: {
      selectedFilterOptions: {},
      selectedFilterValues: {},
    },
};

const toolings = createSlice({
    name: 'toolings',
    initialState,
    reducers: {
      updateToolingListRequest(state, action: PayloadAction<ToolingListRequest>) {
        state.toolingListRequest = action.payload;
      },
      updateToolingListViewSelectedFilterOptions(state, action: PayloadAction<any>) {
        state.toolingListFilter.selectedFilterOptions = action.payload;
      },
      updateToolingListViewSelectedFilterValues(state, action: PayloadAction<SelectedFilters>) {
        state.toolingListFilter.selectedFilterValues = action.payload;
      },
    },
  });
  
  export const { updateToolingListRequest, updateToolingListViewSelectedFilterOptions, updateToolingListViewSelectedFilterValues } = toolings.actions;
  
  export default toolings.reducer;