import { useState, useEffect } from "react";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { Collapse, Divider, List, makeStyles } from "@mui/material";
import { SUBMENU_TEXT, CSSDefaults, sidebarMenuItems, ROUTES_WITH_PERMISSIONS } from "../models/GlobalConstants";
import * as Icons from "@mui/icons-material"; // Import all icons from MUI
import ChevronRightOutlinedIcon from "@mui/icons-material/ChevronRightOutlined";
import { NavLink, useLocation } from "react-router-dom";
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { Permissions } from "../models/Enum";
import SessionService from "../services/SessionService";

type SideBarProps = {
  onActiveMenuChange: any;
  open: boolean;
}

export default function Sidebar(props: SideBarProps) {
  const smallerWidthIconStyle = { minWidth: "unset", marginRight: 2 };
  const [openMenu, setOpenMenu] = useState("");
  const [currentPageMenu, setCurrentPageMenu] = useState("");
  const location = useLocation();  

  const sessionService = SessionService.getInstance();

  const { open, onActiveMenuChange } = props;

  const getIconComponent = (iconName) => {
    const IconComponent = Icons[iconName]; // Get the icon dynamically using bracket notation
    return IconComponent ? <IconComponent /> : null; // Return the icon component if found
  };

  useEffect(() => {
    const activeParentMenu = sidebarMenuItems.find(m => m.path === location.pathname || 
        m.subMenus?.some(sm => sm.path === location.pathname));
    
    if(activeParentMenu) {
      setCurrentPageMenu(activeParentMenu.title);
      
      // ** No Longer Needed due to could not have to menu stay open for other permision in route if it not first **/

      // const permissionPath = !ROUTES_WITH_PERMISSIONS.some(({path, permission}) => {
      //   return !sessionService.hasPermission(permission) && location.pathname === path
      // });
      // setOpenMenu(permissionPath ? activeParentMenu.title: " ")
      setOpenMenu(activeParentMenu.title);
      onActiveMenuChange(activeParentMenu, activeParentMenu.subMenus?.find(sm => sm.path === location.pathname));
    }
  }, [location])

  return (
    <>
      {sidebarMenuItems.map((menu) => (
        <div key={menu.title}>
          {(!(!sessionService.hasPermission(Permissions.CreateUser) && menu.title === SUBMENU_TEXT.USER_ROLE_SIDEBAR_MENU_TEXT)
          || !(!sessionService.hasPermission(Permissions.UpdateUser) && menu.title === SUBMENU_TEXT.USER_ROLE_SIDEBAR_MENU_TEXT)
          || !(!sessionService.hasPermission(Permissions.ManageUserRoles) && menu.title === SUBMENU_TEXT.USER_ROLE_SIDEBAR_MENU_TEXT)
          || !(!sessionService.hasPermission(Permissions.ViewUsers) && menu.title === SUBMENU_TEXT.USER_ROLE_SIDEBAR_MENU_TEXT))
          && !(!(sessionService.hasPermission(Permissions.ManageAppSettings) || sessionService.hasPermission(Permissions.ViewAppSetting)) 
          && menu.title === SUBMENU_TEXT.APP_SETTING_SIDEBAR_MENU_TEXT)
          && !(!sessionService.hasPermission(Permissions.ExecutiveDashboard) && menu.title === SUBMENU_TEXT.DASHBOARD)
          && !(!sessionService.hasPermission(Permissions.ProjectOverviewDashboard) && menu.title === SUBMENU_TEXT.PROJECT_OVERVIEW__DASHBOARD)
          && <ListItemButton
            selected={currentPageMenu === menu.title} onClick={() => setOpenMenu(menu.title)}
            sx={{
                "&.Mui-selected": {
                  backgroundColor: CSSDefaults.sideBarActiveMenuBg
                },
                "&.Mui-focusVisible": {
                  backgroundColor: CSSDefaults.sideBarFocusMenuBg
                },
                ":hover": {
                  backgroundColor: CSSDefaults.sideBarFocusMenuBg
                }
              }}
            >
            <ListItemIcon sx={smallerWidthIconStyle}>
              {getIconComponent(menu.icon)}
            </ListItemIcon>
            {menu.subMenus?.length ? 
              <ListItemText primary={menu.title} />
                :
              <NavLink to={menu.path as string}><ListItemText primary={menu.title} /></NavLink>
            }
            {menu.subMenus?.length && <ChevronRightOutlinedIcon /> }
          </ListItemButton>}
          {menu.subMenus && (
            <Collapse in={open && openMenu === menu.title} timeout="auto" unmountOnExit>
              {menu.subMenus.map((subMenu) => (
                <List key={subMenu.title} className="sub-menu-list" component="div" sx={{ pl: 3 }} disablePadding>
                  {/* Show the View Products - sub menu item if the user has at least one of these product-related permissions */}
                 {!(!sessionService.hasPermission(Permissions.ViewProducts) && !sessionService.hasPermission(Permissions.UpdateProduct) && 
                 !sessionService.hasPermission(Permissions.CreateProduct) && subMenu.title === SUBMENU_TEXT.VIEWS_PRODUCT_SIDEBAR_MENU_TEXT)
                  /* Show the "Create New Product" menu item only if the user has permission to create a product  */
                  &&!(!sessionService.hasPermission(Permissions.CreateProduct) && subMenu.title === SUBMENU_TEXT.CREATE_NEW_PRODUCT_SIDEBAR_MENU_TEXT)
                  /* Show the "Bulk Create Products" menu item only if the user has permission to bulk create products */
                  &&!(!sessionService.hasPermission(Permissions.BulkCreateProducts) && subMenu.title === SUBMENU_TEXT.BULK_CREATE_SIDEBAR_MENU_TEXT)
                  /* Show the "Bulk Update Products" menu item only if the user has permission to bulk update products */
                  &&!(!sessionService.hasPermission(Permissions.BulkUpdateProducts) && subMenu.title === SUBMENU_TEXT.BULK_UPDATE_SIDEBAR_MENU_TEXT)
                  /* Show the "Users" menu item if the user has at least one of these user-related permissions */
                  &&!(!sessionService.hasPermission(Permissions.CreateUser) && !sessionService.hasPermission(Permissions.UpdateUser) &&
                  !sessionService.hasPermission(Permissions.ViewUsers) && subMenu.title === SUBMENU_TEXT.USERS_SIDEBAR_MENU_TEXT)
                  /* Show the "Roles" menu item only if the user has permission to view users or manage user roles */
                  &&!(!sessionService.hasPermission(Permissions.ViewUsers) && !sessionService.hasPermission(Permissions.ManageUserRoles) && 
                  subMenu.title === SUBMENU_TEXT.ROLES_SIDEBAR_MENU_TEXT)
                  /* Show the "Create New Quote" menu item only if the user has permission to manage quotes */
                  &&!(!sessionService.hasPermission(Permissions.ManageQuote) && subMenu.title === SUBMENU_TEXT.CREATE_NEW_QUOTE_SIDEBAR_MENU_TEXT)
                  /* Show the "Bulk Create Quotes" menu item only if the user has permission to manage quotes */
                  &&!(!sessionService.hasPermission(Permissions.ManageQuote) && subMenu.title === SUBMENU_TEXT.BULK_CREATE_QUOTES)
                  /* Show the "Bulk Update Quotes" menu item only if the user has permission to manage quotes */
                  &&!(!sessionService.hasPermission(Permissions.ManageQuote) && subMenu.title === SUBMENU_TEXT.BULK_UPDATE_QUOTES)
                  &&!(!sessionService.hasPermission(Permissions.ViewQuotes) && !sessionService.hasPermission(Permissions.ManageQuote) 
                  && !sessionService.hasPermission(Permissions.ApproveQuoteCost) && !sessionService.hasPermission(Permissions.ApproveDNAndUMAP) &&
                  !sessionService.hasPermission(Permissions.ViewQuoteHistory) &&
                  subMenu.title === SUBMENU_TEXT.VIEW_QUOTES_SIDEBAR_MENU_TEXT)
                  // &&!(!sessionService.hasPermission(Permissions.ManageAssignments) && subMenu.title === SUBMENU_TEXT.ASSIGNMENT_SIDEBAR_MENU_TEXT)
                  // &&!(!sessionService.hasPermission(Permissions.ManageAssignments) && subMenu.title === SUBMENU_TEXT.SECURITY_GROUP_SIDEBAR_MENU_TEXT)
                  &&!(!(sessionService.hasPermission(Permissions.ManageAppSettings) || sessionService.hasPermission(Permissions.ViewAppSetting))
                  && SUBMENU_TEXT.APP_SETTING.includes(subMenu.title))
                  && <ListItemButton>
                    <ListItemIcon sx={smallerWidthIconStyle}>
                      &rarr;
                    </ListItemIcon>
                    <NavLink to={subMenu.path}>{subMenu.title}</NavLink>
                  </ListItemButton>
                  }
                </List>
              ))}
            </Collapse>
          )}
          <Divider />
        </div>
      ))}
    </>
  );
}
