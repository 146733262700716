import React, { useState, useEffect, useCallback } from "react";
import { Autocomplete, TextField } from "@mui/material";
import { debounce } from 'lodash';
import SessionService from "../services/SessionService";
import { QuoteBasicDetail } from "../models/Quotes/QuoteBasicDetail";

interface ProductSearchAutoCompleteProps {
  selectedItem: (productBasicDetail: QuoteBasicDetail | null) => void;
  searchTerm: string;
  setSearchTerm: (newValue: string) => void;
};

const ProductSearchAutoComplete = (props: ProductSearchAutoCompleteProps) => {
  const { selectedItem, searchTerm, setSearchTerm } = props;

  const [searchResult, setSearchResult] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedId, setSelectedId] = useState<string | null>(null);
  const [isSelection, setIsSelection] = useState(false);

  const sessionService = SessionService.getInstance();

  const fetchResults = async (term: string, isSelection: boolean = false) => {
    if (!`${term}`.trim()) {
      setSearchResult([]);
      setLoading(false);
      return;
    }

    const sessionInfo = sessionService.getSessionToken();
    const queryParam = isSelection ? `id=${term}` : `searchVal=${encodeURIComponent(term?.trim().replace(/\./g, '%2E').replace(/\//g, '%2F').replace(/\\/g, '%5C'))}`;
    const apiUrl = `${process.env.REACT_APP_API_URL}/Quote/product-search?${queryParam}`;

    try {
      setLoading(true);
      // Simulating an API call
      const response = await fetch(apiUrl, {
        method: 'GET',
        headers: {
          "Content-Type": "application/json",
          "Authorization": "Bearer " + sessionInfo,
        }
      });
      const searchResponse = await response.json();
      if (searchResponse?.isSuccess) {
        setSearchResult(searchResponse?.data);
      }
    } catch (error) {
      console.error("Error fetching options:", error);
    } finally {
      setLoading(false);
    }
  };

  const debouncedFetchResults = useCallback(
    debounce((term) => fetchResults(term, false), 400),
    []
  );

  useEffect(() => {
    if (isSelection && selectedId) {
      // If selecting from dropdown, call API with id
      fetchResults(selectedId, true);
    } else {
      // If typing, use debounced function
      debouncedFetchResults(searchTerm);
    }

    return () => {
      debouncedFetchResults.cancel();
    };
  }, [searchTerm, selectedId, isSelection, debouncedFetchResults]);

  return (
    <Autocomplete
      size="small"
      style={{ width: '400px' }}
      getOptionLabel={(optionList) => {
        if (!optionList) return ''; // Handle null or undefined options gracefully

        const id = optionList.id != null ? `${optionList.id}` : '';
        const stockcode = optionList.stockcode ? `${optionList.stockcode}` : '';
        const shortDesc = optionList.shortDesc ? `${optionList.shortDesc}` : '';

        // Combine all non-empty parts with a separator
        return [id, stockcode, shortDesc].filter(Boolean).join(' - ');
      }}

      options={searchResult || []}
      inputValue={searchTerm}
      renderInput={(params) => <TextField {...params} />}
      onInputChange={(event, newInputValue, reason) => {
        //if reason is input then new value else null
        if (reason === 'input') {
          setSearchTerm(String(newInputValue ?? ''));
          setIsSelection(false); // Mark as search input change
        }
      }}
      onChange={(event, newValue) => {
        const selectedValue = String(newValue?.id ?? '');
        setSelectedId(selectedValue);
        setSearchTerm(selectedValue);
        setIsSelection(true); // Mark as dropdown selection
        selectedItem(newValue);
      }}
      filterOptions={(options, { inputValue }) => {
        // Custom filtering logic
        const normalizedInput = inputValue.toLowerCase();
        return options.filter((option) => {
          // Check id (convert to string), stockcode, and shortDesc
          return (
            String(option.id).toLowerCase().includes(normalizedInput) ||
            (option.stockcode && option.stockcode.toLowerCase().includes(normalizedInput)) ||
            (option.shortDesc && option.shortDesc.toLowerCase().includes(normalizedInput))
          );
        });
      }}
      renderOption={(props, option) => (
        <li {...props}>
          {`${option.id || ''} ${option.stockcode ? `- ${option.stockcode}` : ''} ${option.shortDesc ? `- ${option.shortDesc}` : ''
            }`}
        </li>
      )}
      //disable clearable because when clear null it would error
      disableClearable={true}
      loading={loading}
    />
  );

};

export default ProductSearchAutoComplete;