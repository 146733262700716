import { configureStore, combineReducers } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import filtersReducer from './reducers/filtersReducer';
import itemsReducer from "./reducers/itemsReducer";
import attachmentsReducer from './reducers/attachmentsReducer';
import attachmentFiltersReducer from './reducers/attachmentFiltersReducer';
import quotesReducer from './reducers/quotesReducer';
import projectDashboardReducer from './reducers/projectDashboardReducer';
import toolingsReducer from './reducers/toolingsReducer';
import globalLoadingReducer from './reducers/globalLoadingReducer';

export type RootState = ReturnType<typeof rootReducer>;
export type AppDispatch = typeof store.dispatch;

// Combine reducers
const rootReducer = combineReducers({
  globalLoading: globalLoadingReducer,
  filters: filtersReducer,
  items: itemsReducer,
  attachments: attachmentsReducer,
  attachmentFilters: attachmentFiltersReducer,
  quotes: quotesReducer,
  projectDashboard: projectDashboardReducer,
  toolings: toolingsReducer
});

// Configure Redux Persist
const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['filters', 'items', 'attachments', 'attachmentFilters', 'quotes', 'projectDashboard', 'toolings'], // Add reducers you want to persist here
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

// Create the Redux store
export const store = configureStore({
  reducer: persistedReducer,
});

export const persistor = persistStore(store);

export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
export const useAppDispatch = () => useDispatch<AppDispatch>();