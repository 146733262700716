import { Box, Button, Fade, Popper, createSvgIcon } from "@mui/material";
import { useEffect, useState } from "react";
import FileDownloadRoundedIcon from '@mui/icons-material/FileDownloadRounded';
import { toast } from "react-toastify";
import moment from "moment";
import SessionService from "../services/SessionService";
import { useAppSelector } from "../store";
import ExportFileDownloadIcon from '@mui/icons-material/SaveAlt';
import FileDownload from "./FileDownload";

interface ExportQuoteListingProps {
  quotesTotalCount: number;
  setLoading: (loading: boolean) => void;
};

const ExportQuoteListing = (props: ExportQuoteListingProps) => {
  const { quotesTotalCount, setLoading } = props;

  const quoteListFilterValuesSelected = useAppSelector((state) => state.filters.quoteListView.selectedFilterValues);

  const [popperAnchorEl, setPopperAnchorEl] = useState<null | HTMLElement>(null);

  const handlePopper = (event: React.MouseEvent<HTMLElement>) => {
    setPopperAnchorEl(popperAnchorEl ? null : event.currentTarget);
  };

  //openPopper check if the popper is open or not;
  const openPopper = Boolean(popperAnchorEl);

  //needed for when use click out of popper
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      //if popper is open and click is not popper then set anchor to null which close the popper
      if (popperAnchorEl && !popperAnchorEl.contains(event.target as Node)) {
        setPopperAnchorEl(null);
      }
    };

    if (openPopper) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    } return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [openPopper]);

  const handleExcelforPricingToolingList = async () => {
    let fileName = 'Quote_Pricing_Tooling_';
    let fileFormat = 'Quote Pricing Tooling';
    if (quotesTotalCount > 5000) {
      toast.error("Cannot export more than 5000 records. Please narrow down the list using filter options and try again");
    }
    else {
      const fileDownload = await FileDownload({ 
        requestInput: quoteListFilterValuesSelected, 
        inputFileName: fileName, 
        inputFileFormat: fileFormat, 
        setLoading 
      });
      await fileDownload.handleDownload();
    }
  };

  const handleCSVForQuoteList = async () => {
    let fileName = 'Quote_List_';
    let fileFormat = 'Quote List';
    if (quotesTotalCount > 5000) {
      toast.error("Cannot export more than 5000 records. Please narrow down the list using filter options and try again");
    }
    else {
      const fileDownload = await FileDownload({ 
        requestInput: quoteListFilterValuesSelected, 
        inputFileName: fileName, 
        inputFileFormat: fileFormat, 
        setLoading 
      });
      await fileDownload.handleDownload();
    }
  };

  return (
    <>
      <Button
        startIcon={<ExportFileDownloadIcon />}
        onClick={handlePopper}
      >
        EXPORT
      </Button>
      <Popper
        open={openPopper}
        anchorEl={popperAnchorEl}
        placement={"bottom-start"}
        modifiers={[
          {
            name: 'flip',
            enabled: false,
          },
          {
            name: 'preventOverflow',
            options: {
              altBoundary: true,
              tether: false,
            },
          },
        ]}
        transition
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={250}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                bgcolor: 'background.paper',
                border: 1,
                borderColor: 'grey.300',
                borderRadius: 1,
                alignItems: "flex-start",
                padding: 1
              }}
            >
              {/* <Button
                startIcon={<FileDownloadRoundedIcon />}
                onClick={handleExcelforPricingToolingList}
              >
                Export Pricing - Tooling to Excel
              </Button> */}
              <Button
                startIcon={<FileDownloadRoundedIcon />}
                onClick={handleCSVForQuoteList}
              >
                Export Quotes to CSV
              </Button>
            </Box>
          </Fade>
        )}
      </Popper>
    </>
  )
};

export default ExportQuoteListing;