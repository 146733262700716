import { useState, useEffect } from "react";
import {
    DataGrid,
    GridActionsCellItem,
    GridCellParams,
    GridColDef,
    GridPaginationModel,
    GridSortModel,
    GridToolbarColumnsButton,
    GridToolbarContainer,
    GridToolbarDensitySelector,
    GridToolbarQuickFilter,
} from "@mui/x-data-grid";
import api from "../../../services/ApiService";
import { ApiResponse } from "../../../models/ApiResponse";
import {
    SelectedFilters,
} from "../../../models/Items/ItemListRequest";
import { Permissions, PermissionsFriendlyNames, SortDirection } from "../../../models/Enum";
import Filters from "../Filters";
import {
    Button,
    Box,
    Tooltip,
} from "@mui/material";
import Loader from "../../../components/Loader";
import EditIcon from "@mui/icons-material/Edit";
import { NO_LOCAL_SEARCH_RESULTS_GRID_MESSAGE, NO_ROWS_GRID_MESSAGE } from "../../../components/GridUtilityComponents";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../store";
import SessionService from "../../../services/SessionService";
import { updateToolingListRequest } from "../../../reducers/toolingsReducer";
import { Add } from "@mui/icons-material";
import { formatAmount } from "../../../utils/helpers";
import { ToolingDetail } from "../../../models/Tooling/ToolingDetail";
import moment from "moment";
import { DATE_TIME_FORMAT } from "../../../utils/constants";

export interface ToolingListResponse {
    toolings?: ToolingDetail[];
}

export default function ToolingList() {
    const [isLoading, setLoading] = useState(false);
    const [toolings, setToolings] = useState<ToolingDetail[]>([]);

    const [filtersExpanded, setFiltersExpanded] = useState<boolean>(true);

    let navigate = useNavigate();

    const dispatch = useAppDispatch();

    const toolingListRequestState = useAppSelector((state) => state.toolings.toolingListRequest);

    const sessionService = SessionService.getInstance();

    const initialToolingListRequest = {
        pageIndex: 1,
        pageSize: 10,
        sortBy: "id",
        sortDirection: SortDirection.DESC,
    };

    const actionColumn = {
        field: "actions",
        headerName: "Action",
        sortable: false,
        width: 100,
        renderCell: (params: GridCellParams) => {
            const { seriesName } = params?.row || {};
            const hasPermission = sessionService.hasPermission(Permissions.ManageQuote);

            const handleEditClick = () => {
                navigate(`/create-tooling`, { state: { seriesName, viewMode: "EditButtonClickFromToolingList" } });
            };

            return (
                <div style={{ display: "flex", justifyContent: "flex-start", width: "100%" }}>
                    {hasPermission &&
                        <Tooltip title="Edit Tooling" arrow>
                            <GridActionsCellItem
                                icon={<EditIcon />}
                                onClick={handleEditClick}
                                label="Edit"
                            />
                        </Tooltip>}
                </div>
            );
        },
    };

    const ToolingListGridColumns: GridColDef[] = [
        {
            field: "seriesName",
            headerName: "Series Name",
            width: 220,
            disableColumnMenu: true,
            sortable: false,
        },
        {
            field: "runningTotal",
            headerName: "Tooling Running Total",
            sortable: false,
            disableColumnMenu: true,
            width: 200,
            valueGetter: (params) => params?.value ? formatAmount(params.value, "USD") : ''
        },
        {
            field: "totalBudget",
            headerName: "Tooling Budget",
            sortable: false,
            disableColumnMenu: true,
            width: 200,
            valueGetter: (params) => params?.value ? formatAmount(params.value, "USD") : ''
        },
        {
            field: "updatedDate",
            headerName: "Updated Date",
            sortable: false,
            disableColumnMenu: true,
            width: 200,
            valueFormatter: (params) => moment(params?.value).format(DATE_TIME_FORMAT)
        },
    ];

    // mapping used to find original column name that should be used e.g. for sortBy parameter
    const ToolingListReferenceColumnsMapping = {
        seriesName: "id",
    };

    const gridToolbar = () => (
        <GridToolbarContainer>
            <div style={{ marginLeft: "12px" }}>
                <GridToolbarColumnsButton />
                <GridToolbarDensitySelector />
            </div>
            <GridToolbarQuickFilter sx={{ margin: "5px 30px 5px 5px", width: "20%" }} />
            <div
                style={{
                    flexGrow: 1, textAlign: "center", padding: 5,
                    display: "flex", justifyContent: "flex-end"
                }}
            >
                {sessionService.hasPermission(Permissions.ManageQuote) &&
                    <Button variant="contained"
                        component="a"
                        href="/create-tooling"
                        style={{ padding: "12px" }}
                    >
                        <Add />
                        Create New Tooling
                    </Button>}
            </div>
        </GridToolbarContainer>
    );

    useEffect(() => {
        getToolings();
    }, [toolingListRequestState]);

    const getToolings = async () => {
        setLoading(true);

        api.post<ApiResponse<ToolingListResponse>>("/Tooling/list", toolingListRequestState)
            .then((response) => {
                setLoading(false);
                if (response.isSuccess) {

                    const toolingsList: ToolingDetail[] = (response?.data?.toolings || []).map((tooling, index) => ({
                        ...tooling,
                        id: index + 1, // Assigning a unique id based on index
                    }));

                    setToolings(toolingsList);
                } else {
                    throw new Error(response.message);
                }
            })
            .catch((error) => {
                setLoading(false);
                console.error("Exception from Tooling list API", error);
            });
    };

    // convert state sortBy value to column name that is bound in grid
    const getReverseSortFieldName = (fieldName: string): string => {
        const gridFieldName = Object.keys(ToolingListReferenceColumnsMapping).find(
            (prmKey) => ToolingListReferenceColumnsMapping[prmKey] === fieldName
        );
        return gridFieldName || fieldName;
    };

    const onPaginationChange = (pagination: GridPaginationModel) => {
        dispatch(updateToolingListRequest({
            ...toolingListRequestState,
            pageIndex: pagination.page + 1,
            pageSize: pagination.pageSize,
        }));
    };

    const handleApplyFilterClick = (filterValues: SelectedFilters) => {
        if (Object.values(filterValues)?.length) {
            dispatch(updateToolingListRequest({
                ...toolingListRequestState,
                ...filterValues,
                pageIndex: 1,
            }));
        } else {
            //reset the filters
            dispatch(updateToolingListRequest(initialToolingListRequest));
        }
    };

    const handleExpandClick = () => {
        setFiltersExpanded(!filtersExpanded);
    };

    return (
        <>
            <Filters
                onApplyFilter={handleApplyFilterClick}
                filtersExpanded={filtersExpanded}
                handleExpandClick={handleExpandClick}
                viewMode={PermissionsFriendlyNames[Permissions.ViewToolings]}
            // viewMode={"View Toolings"}
            />
            <Loader isLoading={isLoading} />
            <Box
                sx={{
                    height: "70%",
                    width: "100%",
                    display: "block",
                }}
            >
                <DataGrid
                    rows={toolings}
                    columns={[actionColumn, ...ToolingListGridColumns]}
                    initialState={{
                        pagination: {
                            paginationModel: {
                                pageSize: 10
                            },
                        },
                    }}
                    rowCount={toolings.length}
                    pageSizeOptions={[10, 25, 50, 100]}
                    disableRowSelectionOnClick={true}
                    disableColumnFilter={true}
                    sortingMode="server"
                    paginationMode="server"
                    sortModel={[
                        {
                            field: getReverseSortFieldName(toolingListRequestState.sortBy),
                            sort:
                                toolingListRequestState.sortDirection == SortDirection.ASC
                                    ? "asc"
                                    : "desc",
                        },
                    ]}
                    // onSortModelChange={onSortChange}
                    onPaginationModelChange={onPaginationChange}
                    paginationModel={{
                        page: toolingListRequestState.pageIndex - 1,
                        pageSize: toolingListRequestState.pageSize,
                    }}
                    slots={{
                        toolbar: gridToolbar,
                        noRowsOverlay: () => NO_ROWS_GRID_MESSAGE,
                        noResultsOverlay: () => NO_LOCAL_SEARCH_RESULTS_GRID_MESSAGE,
                    }}
                    getRowHeight={() => 'auto'}
                    columnVisibilityModel={{
                        actions: (
                            sessionService.hasPermission(Permissions.ManageQuote)
                        )
                    }}
                />
            </Box>
        </>
    );
}