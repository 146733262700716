import React, { useEffect, useState } from "react";
import {
  Box,
  TextField,
  Button,
  Typography,
  Switch,
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Card,
  CardContent,
  FormControlLabel,
  FormControl,
  Autocomplete,
  IconButton,
  Tooltip,
  InputAdornment,
} from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import * as Yup from "yup";
import { useFormik } from "formik";
import moment from "moment";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { DataGrid } from "@mui/x-data-grid";
import { ProductFilterOptions } from "../../../models/Items/FilterOptions";
import { ApiResponse } from "../../../models/ApiResponse";
import api from "../../../services/ApiService";
import { ToolingDetail } from "../../../models/Tooling/ToolingDetail";
import { ToolingList } from "../../../models/Tooling/ToolingList";
import { CreateUpdateToolingRequest } from "../../../models/Tooling/CreateUpdateToolingRequest";
import EditIcon from "@mui/icons-material/Edit";
import { DATE_FORMAT, DATE_TIME_FORMAT } from "../../../utils/constants";
import { CSSDefaults } from "../../../models/GlobalConstants";
import GetFileType from "../../../components/GetFileType";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DownloadIcon from "@mui/icons-material/Download";
import GetIconByFileType from "../../../components/GetIconByFileType";
import PDFViewModal, {
  PDFDetail,
} from "../../Report/ViewAttachment/PDFViewModal";
import { toast } from "react-toastify";
import { useLocation } from "react-router-dom";
import DeleteValidationModal from "../../../components/ManageSettingComponents/DeleteValidationModal";
import { GRID_ACTION_DELETE_ICON } from "../../../components/GridUtilityComponents";
import { formatAmount } from "../../../utils/helpers";
import { toolingValidExtensions, validExtensions } from "../EditItem/Constants";

const CreateUpdateTooling: React.FC = () => {
  const [accordianExpanded, setAccordianExpanded] = useState<boolean>(true);
  const [selectedDropDownOptions, setSelectedDropDownOptions] = useState<any>(
    {}
  );
  const [toolingGridData, setToolingGridData] = useState<ToolingList[]>([]);
  const [seriesNameDropdownOptions, setSeriesNameDropdownOptions] = useState<
    string[]
  >([]);
  const [disableSubmit, setDisableSubmit] = useState<boolean>(false);
  const [isPDFViewOpen, setIsPDFViewOpen] = useState(false);
  const [pdfDetailView, setPDFDetailView] = useState<PDFDetail>();
  const [deleteModalOpen, setDeleteOpenModal] = useState(false);
  const [toolingId, setToolingId] = useState(null);

  const location = useLocation();

  const { seriesName = "", viewMode = "" } = location.state || {};

  const initialValues: CreateUpdateToolingRequest = {
    runningTotal: null,
    totalBudget: null,
    seriesName: null,
    amount: null,
    carNo: "",
    releaseDate: null,
    file: null,
    prName: "",
    poNo: "",
    isOpened: false,
  };

  const validations = Yup.object().shape({
    seriesName: Yup.string()
      .trim()
      .nullable()
      .required("Series Name is required"),
    amount: Yup.string()
      .trim()
      .nullable()
      .required("Tooling Amount is required"),
    carNo: Yup.string().trim().nullable().required("Tooling CAR# is required"),
    releaseDate: Yup.string()
      .trim()
      .nullable()
      .required("Release Date is required"),
    file: Yup.mixed().required("Tooling Quote is required"),
    prName: Yup.string()
      .trim()
      .nullable()
      .required("Tooling PR Name is required"),
    poNo: Yup.string().trim().nullable().required("Tooling PO# is required"),
  });

  const getFormattedDate = (fieldName: string) => {
    return formik.values[fieldName]
      ? moment(formik.values[fieldName]).format(DATE_FORMAT)
      : null;
  };

  const formik = useFormik({
    initialValues,
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: validations,
    onSubmit: async (values) => {
      const data = {
        ...values,
        releaseDate: getFormattedDate("releaseDate"),
      };

      console.log(data);
      setDisableSubmit(true);
      try {
        let response: ApiResponse<boolean>;
        const formData = new FormData();

        // Append non-file fields
        Object.keys(data).forEach((key) => {
          if (key === "releaseDate") {
            formData.append(key, data.releaseDate ?? "");
          } else {
            formData.append(key, values[key] ?? "");
          }
        });

        if (data.id) {
          // Update tooling
          response = await api.put<ApiResponse<boolean>>("/Tooling", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          });
        } else {
          // Create new tooling
          response = await api.post<ApiResponse<boolean>>(
            "/Tooling",
            formData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            }
          );
        }

        // Handle successful response here, if needed
        if (response?.isSuccess) {
          toast.success(response?.message);
          setDisableSubmit(false);
          fetchToolingDetailsBySeriesName(data?.seriesName);
        }
      } catch (error) {
        console.error("Create / Update Tooling API Failed", error);
      } finally {
        setDisableSubmit(false); // Re-enable the submit button
      }
    },
  });

  useEffect(() => {
    fetchSeriesDropdownOptions();
  }, []);

  useEffect(() => {
    if (viewMode === "EditButtonClickFromToolingList" && seriesName) {
      fetchToolingDetailsBySeriesName(seriesName);
      setSelectedDropDownOptions({
        seriesName,
      });
    }
  }, [viewMode]);

  /* 
    Fetches filter options and set it to the state
   */
  const fetchSeriesDropdownOptions = async () => {
    try {
      const dropDownSources = await api.get<ApiResponse<ProductFilterOptions>>(
        `/Item/filter-options`
      );
      if (dropDownSources?.data?.seriesNames?.length) {
        setSeriesNameDropdownOptions(dropDownSources.data.seriesNames);
      }
    } catch (error) {
      console.error("Exception from Series Name Options API", error);
    }
  };

  /* 
    Fetch Tooling details by Series Name and set it to the state
   */
  const fetchToolingDetailsBySeriesName = async (seriesNameSelectedValue) => {
    try {
      const toolingDetailsResponse = await api.get<ApiResponse<ToolingDetail>>(
        `/Tooling/${seriesNameSelectedValue}`
      );

      if (
        toolingDetailsResponse?.isSuccess &&
        toolingDetailsResponse?.data?.seriesName
      ) {
        const { runningTotal, totalBudget, seriesName } =
          toolingDetailsResponse.data;
        formik.setValues({
          ...formik.initialValues, // Reset all values
          runningTotal,
          totalBudget,
          seriesName,
        });

        const toolingListData = toolingDetailsResponse?.data?.toolings || [];
        setToolingGridData(toolingListData);
      }
    } catch (error) {
      console.error("Exception from Tooling Details API", error);
    }
  };

  /**
   * This function is used to render text field
   * @param fieldName - Name of the field
   * @param label - Label of the field
   * @param numeric - Boolean for numeric type field
   */
  const renderTextField = (
    fieldName,
    label,
    numeric = false,
    required = true,
    currency = false
  ) => (
    <FormControl style={{ width: "100%" }}>
      <label htmlFor={`outlined-${label}`} style={{ marginBottom: "8px" }}>
        {label}
        {required && (
          <span className="required-field-asterisk-icon">{` *`}</span>
        )}
      </label>
      <TextField
        id={`outlined-${label}`}
        value={formik.values[fieldName] || ""}
        label={formik.errors[fieldName] ? formik.errors[fieldName] : ""}
        onChange={formik.handleChange}
        name={fieldName}
        InputProps={{
          startAdornment: (currency && formik.values[fieldName]) ? <InputAdornment position="start">$</InputAdornment> : null
        }}
        onKeyDown={(e) =>
          (e.key === "ArrowUp" || e.key === "ArrowDown") && e.preventDefault()
        }
        onFocus={(e) =>
          e.target.addEventListener(
            "wheel",
            function (e) {
              e.preventDefault();
            },
            { passive: false }
          )
        }
        className="hide-input-arrow"
        inputProps={{ type: numeric ? "number" : "text" }}
        error={formik.touched[fieldName] && Boolean(formik.errors[fieldName])}
      />
    </FormControl>
  );

  /**
   * This function is used to render date picker field
   * @param fieldName - Name of the field
   * @param label - Label of the field
   */
  const renderDatePicker = (fieldName, label) => (
    <FormControl style={{ width: "100%" }}>
      <label
        htmlFor={`outlined-${fieldName}`}
        style={{ textTransform: "capitalize", marginBottom: "8px" }}
      >
        {label}
        <span className="required-field-asterisk-icon">{` *`}</span>
      </label>
      <TextField
        type="date"
        name={fieldName}
        label={formik.errors[fieldName] ? formik.errors[fieldName] : ""}
        value={moment(formik.values[fieldName]).format("YYYY-MM-DD")}
        onChange={(e) => {
          const value = e.target.value;
          formik.setFieldValue(
            fieldName,
            value ? moment(e.target.value, "YYYY-MM-DD").toDate() : null
          );
        }}
        error={formik.touched[fieldName] && Boolean(formik.errors[fieldName])}
        onBlur={formik.handleBlur}
      />
    </FormControl>
  );

  const renderSwitchField = (fieldName, label) => (
    <FormControl style={{ width: "100%", marginLeft: "20px" }}>
      <label htmlFor={`outlined-${label}`} style={{ marginBottom: "8px" }}>
        {label}
      </label>
      <FormControlLabel
        control={
          <Switch
            checked={(formik.values[fieldName] as boolean) || false}
            onChange={formik.handleChange}
            name={fieldName}
            color="primary"
          />
        }
        label=""
        sx={{ width: "fit-content" }}
      />
    </FormControl>
  );

  const validateAndSetFile = (event, fieldName, formik) => {
    const file: File = event.target.files?.[0];
  
    if (!file) return; // If no file is selected, exit
  
    const allowedExtensions = [toolingValidExtensions.xls, toolingValidExtensions.xlsx, validExtensions.pdf];
    
    if (!allowedExtensions.includes(GetFileType(file.name).fileExtension)) {
      toast.error("Invalid file extension");
      return;
    }
  
    // If valid, set the file in Formik state
    formik.setFieldValue(fieldName, file);
  };

  const renderFileUpload = (fieldName, label) => {
    const fieldValue = formik.values[fieldName];

    return (
      <FormControl fullWidth>
        <label htmlFor={`outlined-${label}`} style={{ marginBottom: "8px" }}>
          {label}
          <span className="required-field-asterisk-icon">{` *`}</span>
        </label>
        <Button
          variant="contained"
          fullWidth
          component="label"
          sx={{
            textTransform: "none",
            height: "56px",
            display: "flex",
            alignItems: "center",
            justifyContent: "start",
          }}
        >
          {/* Ensure fieldValue is a File before accessing .name */}
          {fieldValue instanceof File ? (
            fieldValue.name
          ) : (
            <span
              style={{
                color:
                  formik.touched[fieldName] && Boolean(formik.errors[fieldName])
                    ? CSSDefaults.errorColor
                    : "inherit",
              }}
            >
              ADD EXCEL FILE OR PDF
            </span>
          )}
          <input
            type="file"
            hidden
            onChange={(e) => {
              validateAndSetFile(e, fieldName, formik);
            }}
            accept=".xls,.xlsx,.pdf"
          />
        </Button>
      </FormControl>
    );
  };

  /**
   * This function is used to render autocomplete with dropdown option list
   * @param fieldName - Name of the field
   * @param label - Label of the field
   * @param optionList - Dropdown option array list
   * @param optionLabelGetter - Custom label for dropdown option list
   */
  const renderAutocomplete = (
    fieldName: string = "",
    label: string = "",
    optionList: Array<any> | undefined,
    optionLabelGetter: (option) => string = null
  ) => (
    <FormControl
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        width: "40%",
      }}
    >
      <label
        htmlFor={`outlined-${fieldName}`}
        style={{ marginBottom: "8px", marginRight: "15px" }}
      >
        {label}
        {/* <span className="required-field-asterisk-icon">{` *`}</span> */}
      </label>
      <Autocomplete
        id={`outlined-${fieldName}`}
        value={
          selectedDropDownOptions[fieldName]
            ? selectedDropDownOptions[fieldName]
            : null
        }
        onChange={(event, newValue) =>
          handleAutocompleteOptionsChange(fieldName, newValue)
        }
        onBlur={formik.handleBlur}
        options={optionList ? optionList : []}
        getOptionLabel={(option) =>
          optionLabelGetter ? optionLabelGetter(option) : option.name || option
        }
        // isOptionEqualToValue={(option, value) => option.id === value.id}
        renderInput={(params) => (
          <TextField
            error={Boolean(formik.errors[fieldName])}
            label={
              formik.errors[fieldName] ? String(formik.errors[fieldName]) : ""
            }
            key={params.id}
            {...params}
            placeholder="- Select -"
          />
        )}
        style={{ flex: 1 }}
        // disabled={isFieldDisabled(fieldName)}
        disableClearable={true}
      />
    </FormControl>
  );

  /**
   * This function is used to handle Autocomplete dropdown value
   * @param fieldName - Name of the field
   * @param selectedItem - Selected option value
   */
  const handleAutocompleteOptionsChange = (fieldName, selectedItem) => {
    console.log(fieldName, selectedItem);
    if (!selectedItem) {
      formik.setFieldValue(fieldName, undefined);
      setSelectedDropDownOptions({
        ...selectedDropDownOptions,
        [fieldName]: undefined,
      });
    } else {
      formik.setFieldValue(fieldName, selectedItem);
      setSelectedDropDownOptions({
        ...selectedDropDownOptions,
        [fieldName]: selectedItem,
      });

      fetchToolingDetailsBySeriesName(selectedItem);
    }
  };

  const handleAttachmentDownload = async (link: string) => {
    try {
      window.open(
        `${
          process.env.REACT_APP_API_URL
        }/Attachment/RetrieveFile?fileName=${encodeURIComponent(link)}`,
        "_blank"
      );
    } catch (error: any) {
      console.error("Exception from RetrieveFile API", error);
    }
  };

  const deleteSubmit = async () => {
    api
      .delete<ApiResponse<boolean>>(`/Tooling/${toolingId}`)
      .then(async (response: any) => {
        if (response?.isSuccess) {
          toast.success(response?.message);
          setDeleteOpenModal(false);
          await fetchToolingDetailsBySeriesName(formik.values.seriesName);
        }
      })
      .catch((error) => {
        setDeleteOpenModal(false);
        toast.error(error?.message);
      });
  };

  const handlePDFViewer = (link) => {
    setPDFDetailView({
      fileName: link,
    });
    setIsPDFViewOpen(true);
  };

  const ToolingGridColumns = [
    {
      field: "actions",
      headerName: "Actions",
      width: 120,
      sortable: false,
      renderCell: (params) => (
        <div
          style={{
            display: "flex",
            justifyContent: "flex-start",
            width: "100%",
          }}
        >
          <Tooltip title="Edit Tooling" arrow>
            <IconButton
              onClick={() =>
                formik.setValues({ ...formik.values, ...params.row })
              }
            >
              <EditIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Delete Tooling" arrow>
            {GRID_ACTION_DELETE_ICON(params, handleDeleteModalOpen)}
          </Tooltip>
        </div>
      ),
    },
    { field: "id", headerName: "Id", width: 90 },
    { field: "seqNo", headerName: "Seq #", width: 90 },
    {
      field: "amount",
      headerName: "Tooling Amount",
      width: 150,
      valueGetter: (params) =>
        params.row.amount ? formatAmount(params.row.amount, "USD") : "",
    },
    { field: "carNo", headerName: "Tooling CAR#", width: 140 },
    {
      field: "releaseDate",
      headerName: "Release Date",
      width: 120,
      valueGetter: (params) =>
        params.row.releaseDate
          ? moment(params.row.releaseDate).format("YYYY-MM-DD")
          : "",
    },
    { field: "prName", headerName: "Tooling PR Name", width: 150 },
    { field: "poNo", headerName: "Tooling PO#", width: 120 },
    {
      field: "isOpened",
      headerName: "Tooling Opened",
      width: 140,
      valueGetter: (params) => (params.row.isOpened ? "Yes" : "No"),
    },
    {
        field: "updatedDate",
        headerName: "Updated Date",
        sortable: false,
        width: 150,
        valueFormatter: (params) => moment(params?.value).format(DATE_TIME_FORMAT)
    },
    { field: "fileName", headerName: "File Name", width: 200 },
    {
      field: "link",
      headerName: "Preview",
      renderCell: (params) => {
        return (
          <div className="attachment-grid-image-cell">
            <Tooltip title={params.row.link || ""} arrow>
              <Box
                className="image-thumbnail-container"
                sx={{
                  backgroundColor: "rgba(0,0,0,.05)",
                  height: "66px",
                  width: "66px",
                  textAlign: "center",
                }}
              >
                {
                  //getting the Icon
                  GetIconByFileType(params.row.fileName)
                }
                <div
                  style={{ position: "absolute", display: "flex", gap: "5px" }}
                >
                  <IconButton
                    className="attachment-hover-icon-button"
                    size="small"
                    onClick={() => handleAttachmentDownload(params.row.link)}
                  >
                    <DownloadIcon fontSize="small" />
                  </IconButton>
                  {params?.row?.fileName &&
                    GetFileType(params.row.fileName)?.isPdf && (
                      <IconButton
                        className="attachment-hover-icon-button"
                        size="small"
                        onClick={() => handlePDFViewer(params.row.link)}
                      >
                        <VisibilityIcon fontSize="small" />
                      </IconButton>
                    )}
                </div>
              </Box>
            </Tooltip>
          </div>
        );
      },
      width: 100,
      cellClassName: "sidebarImageListing",
    },
  ];

  const renderToolingGridView = () => {
    return (
      <Box style={{ marginTop: "2%", width: "100%" }}>
        <DataGrid
          rows={toolingGridData || []}
          columns={ToolingGridColumns}
          disableColumnMenu
          sortingOrder={["desc", "asc"]}
          pageSizeOptions={[10, 25, 50, 100]}
          pagination
          paginationMode="client"
          rowCount={toolingGridData.length}
          sortingMode="client"
          getRowHeight={() => "auto"}
          rowSelection={false}
        />
      </Box>
    );
  };

  const closeView = () => {
    setIsPDFViewOpen(false);
  };

  const handleClose = () => {
    setDeleteOpenModal(false);
  };

  const handleDeleteModalOpen = (params) => {
    setDeleteOpenModal(true);
    setToolingId(params.row.id);
  };

  return (
    <>
      {isPDFViewOpen && (
        <PDFViewModal
          pdf={pdfDetailView}
          isOpen={isPDFViewOpen}
          onClose={closeView}
        />
      )}

      {deleteModalOpen && (
        <DeleteValidationModal
          addModalOpen={deleteModalOpen}
          handleCloseModal={handleClose}
          deleteSubmit={deleteSubmit}
          fieldIdentifier={`tooling #${toolingId}`}
        />
      )}

      <Card sx={{ marginBottom: 1 }}>
        <CardContent>
          <form onSubmit={formik.handleSubmit}>
            <Box>
              {renderAutocomplete(
                "seriesName",
                "Search by Series Name:",
                seriesNameDropdownOptions
              )}
            </Box>
            <Accordion
              expanded={accordianExpanded}
              onChange={() => setAccordianExpanded(!accordianExpanded)}
            >
              <AccordionSummary
                expandIcon={<ArrowDropDownIcon />}
                className={"active-accordian-expanded"}
              >
                {accordianExpanded ? <RemoveIcon /> : <AddIcon />}
                <Typography variant="h6">Tooling</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Box p={2} mb={2}>
                  <Grid container spacing={2} padding={2}>
                    <Grid item xs={4}>
                      {renderTextField("amount", "Tooling Amount", true, true, true)}
                    </Grid>
                    <Grid item xs={4}>
                      {renderTextField("carNo", "Tooling CAR#")}
                    </Grid>
                    <Grid item xs={4}>
                      {renderDatePicker("releaseDate", "Release Date")}
                    </Grid>
                    <Grid item xs={4}>
                      {renderFileUpload("file", "Tooling Quote")}
                    </Grid>
                    <Grid item xs={4}>
                      {renderTextField("prName", "Tooling PR Name")}
                    </Grid>
                    <Grid item xs={2}>
                      {renderTextField("poNo", "Tooling PO#")}
                    </Grid>
                    <Grid item xs={2} display="flex" alignItems="center">
                      {renderSwitchField("isOpened", "Tooling Opened")}
                    </Grid>
                  </Grid>
                </Box>
              </AccordionDetails>
            </Accordion>
            <Box>
              <Button
                type="submit"
                variant="contained"
                style={{ margin: "8px 5px", width: "135px" }}
                disabled={disableSubmit}
              >
                Submit
              </Button>
            </Box>
          </form>
          <div style={{ marginTop: "30px" }}>
            <Typography
              variant="h5"
              fontWeight="bold"
              margin={2}
              textAlign={"center"}
            >
              Toolings
            </Typography>
            {toolingGridData?.length > 0 && renderToolingGridView()}
            {toolingGridData?.length === 0 && (
              <Typography variant="body1" margin={3} textAlign={"center"}>
                No records found
              </Typography>
            )}
          </div>
          {/* New Section for Tooling Running Total and Tooling Budget */}
          {toolingGridData?.length > 0 && (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              mt={3}
            >
              <Typography variant="body2" sx={{ mx: 3 }}>
                Tooling Running Total:&nbsp;
                <span style={{ fontWeight: "normal" }}>
                  {formatAmount(formik.values.runningTotal || 0, "USD")}
                </span>
              </Typography>
              <Typography variant="body2" sx={{ mx: 3 }}>
                Tooling Budget:&nbsp;
                <span style={{ fontWeight: "normal" }}>
                  {formatAmount(formik.values.totalBudget || 0, "USD")}
                </span>
              </Typography>
            </Box>
          )}
        </CardContent>
      </Card>
    </>
  );
};

export default CreateUpdateTooling;
