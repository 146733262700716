import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SelectedFilters } from "../models/Items/ItemListRequest";

type FiltersState = {
  selectedFilterOptions: any;
  selectedFilterValues: SelectedFilters;
  reviewRequiredTagId?: number;
}

type ProductListState = {
  productListView: FiltersState;
  dashboardView: FiltersState;
  quoteListView: FiltersState;
  toolingListView: FiltersState;
};

const initialSelectedFilters: SelectedFilters = {
  hideReleasedToInriver: true,
  hideArchivedDevelopment: true,
};

const initialState: ProductListState = {
  productListView: {
    selectedFilterOptions: {},
    selectedFilterValues: initialSelectedFilters,
  },
  quoteListView: {
    selectedFilterOptions: {},
    selectedFilterValues: initialSelectedFilters,
  },
  dashboardView: {
    selectedFilterOptions: {},
    selectedFilterValues: initialSelectedFilters,
    reviewRequiredTagId: null
  },
  toolingListView: {
    selectedFilterOptions: {},
    selectedFilterValues: {},
  },
};

const filters = createSlice({
  name: 'filters',
  initialState,
  reducers: {
    updateProductListViewSelectedFilterOptions(state, action: PayloadAction<any>) {
      state.productListView.selectedFilterOptions = action.payload;
    },
    updateProductListViewSelectedFilterValues(state, action: PayloadAction<SelectedFilters>) {
      state.productListView.selectedFilterValues = action.payload;
    },
    updateQuoteListViewSelectedFilterOptions(state, action: PayloadAction<any>) {
      state.quoteListView.selectedFilterOptions = action.payload;
    },
    updateQuoteListViewSelectedFilterValues(state, action: PayloadAction<SelectedFilters>) {
      state.quoteListView.selectedFilterValues = action.payload;
    },
    updateDashboardViewSelectedFilterOptions(state, action: PayloadAction<any>) {
      state.dashboardView.selectedFilterOptions = action.payload;
    },
    updateDashboardViewSelectedFilterValues(state, action: PayloadAction<SelectedFilters>) {
      state.dashboardView.selectedFilterValues = action.payload;
    },
    setDashboardViewReviewRequiredTagId(state, action: PayloadAction<number>) {
      state.dashboardView.reviewRequiredTagId = action.payload;
    }
  },
});

export const { 
  updateProductListViewSelectedFilterOptions, 
  updateProductListViewSelectedFilterValues,
  updateQuoteListViewSelectedFilterOptions, 
  updateQuoteListViewSelectedFilterValues,
  updateDashboardViewSelectedFilterOptions,
  updateDashboardViewSelectedFilterValues,
  setDashboardViewReviewRequiredTagId
 } = filters.actions;

export default filters.reducer;
