import { Permissions } from "./Enum";

export const CSSDefaults = {
  headerBgColor: "#fff",
  headerFontColor: "#000",
  primaryColor: "#454545",
  purpleHues1: "#a881af",
  yellow: "#ffbd03",
  greenHues1: "#5dbea3",
  greenHues2: "#5adbb5",
  lightColor: "rgba(0, 0, 0, 0.04)",
  sideBarActiveMenuBg: "rgba(0, 0, 0, 0.04)",
  sideBarFocusMenuBg: "rgba(0, 0, 0, 0.04)",
  lightGrey: "rgba(0, 0, 0, 0.6)",
  errorColor: "#d32f2f"
};

export const SUBMENU_TEXT = {
  DASHBOARD: "Executive Dashboard",
  PROJECT_OVERVIEW__DASHBOARD: "Project Overview Dashboard",
  CREATE_NEW_PRODUCT_SIDEBAR_MENU_TEXT: "Create New Product",
  BULK_CREATE_SIDEBAR_MENU_TEXT: "Bulk Create",
  BULK_UPDATE_SIDEBAR_MENU_TEXT: "Bulk Update",
  CREATE_NEW_QUOTE_SIDEBAR_MENU_TEXT: "Create New Quote",
  VIEW_QUOTES_SIDEBAR_MENU_TEXT: "View Quotes",
  BULK_CREATE_QUOTES: "Bulk Create Quotes",
  BULK_UPDATE_QUOTES: "Bulk Update Quotes",
  ASSIGNMENT_SIDEBAR_MENU_TEXT: "Assignment",
  SECURITY_GROUP_SIDEBAR_MENU_TEXT: "Security Group",
  ROLES_SIDEBAR_MENU_TEXT: "Roles",
  USERS_SIDEBAR_MENU_TEXT: "Users",
  VIEWS_PRODUCT_SIDEBAR_MENU_TEXT: "View Products",
  USER_ROLE_SIDEBAR_MENU_TEXT: "Users and Roles",
  APP_SETTING_SIDEBAR_MENU_TEXT: "App Settings",
  
  APP_SETTING: [
    "Manage Tags",
    "Project Stage",
    "Project Status",
    "Factory Status",
    // "Project Coordinator",
    "Attachment Type",
    "Royalty Designer",
    "Marketing Designer",
    "Suppliers",
    "Finishes",
    "Primary Material",
    "Secondary Material",
    "HTS Material",
    "HTS Material Detail",
    "Stockcode Image Hierarchy",
    "Basecode Image Hierarchy",
    "Category (and Sort Order)",
    "Data Type",
    "Data Field Type",
    "Manufacturing Method",
    "Function",
    "Sku Type",
    "Style"
  ]
};

export const ROUTES_WITH_PERMISSIONS = [
  // { path: "/user-dashboard", permission: Permissions.UserDashboard},
  { path: "/executive-dashboard", permission: Permissions.ExecutiveDashboard},
  { path: "/project-dashboard", permission: Permissions.ProjectOverviewDashboard},
  { path: "/create-product", permission: Permissions.CreateProduct},
  { path: "/products", permission: Permissions.ViewProducts || Permissions.CreateProduct || Permissions.UpdateProduct},
  { path: "/quotes", permission: Permissions.ViewQuotes || Permissions.ManageQuote || Permissions.ApproveQuoteCost || Permissions.ApproveDNAndUMAP || Permissions.ViewQuoteHistory},
  { path: "/create-quote", permission: Permissions.ManageQuote},
  { path: "/create-tooling", permission: Permissions.ManageQuote},
  { path: "/view-toolings", permission: Permissions.ManageQuote},
  { path: "/edit-quote/:id", permission: Permissions.ViewQuotes || Permissions.ManageQuote || Permissions.ApproveQuoteCost || Permissions.ApproveDNAndUMAP || Permissions.ViewQuoteHistory},
  { path: "/bulk-create-quote", permission: Permissions.ManageQuote},
  { path: "/bulk-update-quote", permission: Permissions.ManageQuote},
  { path: "/bulk-upload-attachments", permission: Permissions.ManageAttachments},
  { path: "/bulk-update", permission: Permissions.BulkUpdateProducts},
  { path: "/bulk-create", permission: Permissions.BulkCreateProducts},
  { path: "/roles", permission: Permissions.ManageUserRoles || Permissions.ViewUsers},
  { path: "/users", permission: Permissions.CreateUser || Permissions.UpdateUser || Permissions.ViewUsers},
  { path: "/tag", permission: Permissions.ViewAppSetting || Permissions.ManageAppSettings },
  { path: "/project-stage", permission: Permissions.ManageAppSettings || Permissions.ViewAppSetting},
  { path: "/project-status", permission: Permissions.ManageAppSettings || Permissions.ViewAppSetting},
  { path: "/factory-status", permission: Permissions.ManageAppSettings || Permissions.ViewAppSetting},
  // { path: "/project-coordinator", permission: Permissions.ManageAppSettings || Permissions.ViewAppSetting},
  { path: "/attachment-type", permission: Permissions.ManageAppSettings || Permissions.ViewAppSetting},
  { path: "/royalty-designer", permission: Permissions.ManageAppSettings || Permissions.ViewAppSetting},
  { path: "/marketing-designer", permission: Permissions.ManageAppSettings || Permissions.ViewAppSetting},
  { path: "/suppliers", permission: Permissions.ManageAppSettings || Permissions.ViewAppSetting},
  { path: "/finishes", permission: Permissions.ManageAppSettings || Permissions.ViewAppSetting},
  { path: "/primary-material", permission: Permissions.ManageAppSettings || Permissions.ViewAppSetting},
  { path: "/secondary-material", permission: Permissions.ManageAppSettings || Permissions.ViewAppSetting},
  { path: "/hts-material", permission: Permissions.ManageAppSettings || Permissions.ViewAppSetting},
  { path: "/hts-material-detail", permission: Permissions.ManageAppSettings || Permissions.ViewAppSetting},
  { path: "/stockcode-image-hierarchy", permission: Permissions.ManageAppSettings || Permissions.ViewAppSetting},
  { path: "/basecode-image-hierarchy", permission: Permissions.ManageAppSettings || Permissions.ViewAppSetting},
  { path: "/category-(and-sort-order)", permission: Permissions.ManageAppSettings || Permissions.ViewAppSetting},
  { path: "/data-type", permission: Permissions.ManageAppSettings || Permissions.ViewAppSetting},
  { path: "/data-field-type", permission: Permissions.ManageAppSettings || Permissions.ViewAppSetting},
  { path: "/manufacturing-method", permission: Permissions.ManageAppSettings || Permissions.ViewAppSetting},
  { path: "/function", permission: Permissions.ManageAppSettings || Permissions.ViewAppSetting},
  { path: "/sku-type", permission: Permissions.ManageAppSettings || Permissions.ViewAppSetting},
  { path: "/style", permission: Permissions.ManageAppSettings || Permissions.ViewAppSetting},
  // { path: "/assignment", permission: Permissions.ManageAssignments},
  // { path: "/security-group", permission: Permissions.ManageAssignments}
];

export const sidebarMenuItems = [
  {
    title: "User Dashboard",
    path: "/user-dashboard",
    icon: "DashboardCustomizeOutlined",
  },
  {
    title: "Executive Dashboard",
    path: "/executive-dashboard",
    icon: "SpaceDashboardOutlined",
  },
  {
    title: "Project Overview Dashboard",
    path: "/project-dashboard",
    icon: "DashboardCustomizeOutlined",
  },
  {
    title: "Product Management",
    icon: "FolderOutlined",
    subMenus: [
      {
        title: "Create New Product",
        path: "/create-product",
      },
      {
        title: "View Products",
        path: "/products",
      },
      {
        title: "Create New Quote",
        path: "/create-quote",
      },
      {
        title: "View Quotes",
        path: "/quotes",
      },
      {
        title: "Create New Tooling",
        path: "/create-tooling",
      },
      {
        title: "View Toolings",
        path: "/toolings",
      },
      {
        title: "Bulk Create Products",
        path: "/bulk-create",
      },
      {
        title: "Bulk Update Products",
        path: "/bulk-update",
      },
      {
        title: "Bulk Create Quotes",
        path: "/bulk-create-quote",
      },
      {
        title: "Bulk Update Quotes",
        path: "/bulk-update-quote",
      },
      {
        title: "Bulk Upload Attachments",
        path: "/bulk-upload-attachments",
      },
    ],
  },
  {
    title: "Reports",
    icon: "DescriptionOutlined",
    subMenus: [
      // {
      //   title: "Report 1",
      //   path: "/report-1",
      // },
      {
        title: "View Attachment",
        path: "/view-attachment",
      },
    ],
  },
  {
    title: "Users and Roles",
    icon: "PeopleOutlined",
    subMenus: [
      {
        title: "Roles",
        path: "/roles",
      },
      {
        title: "Users",
        path: "/users",
      },
      {
        title: "Assignment",
        path: "/assignment",
      },
      {
        title: "Security Group",
        path: "/security-group",
      },
    ],
  },
  {
    title: "App Settings",
    icon: "SettingsOutlined",
    subMenus: [
      {
        title: "Attachment Type",
        path: "/attachment-type",
      },
      {
        title: "Basecode Image Hierarchy",
        path: "/basecode-image-hierarchy",
      },
      {
        title: "Category (and Sort Order)",
        path: "/category-(and-sort-order)",
      },
      {
        title: "Data Field Type",
        path: "/data-field-type",
      },
      {
        title: "Data Type",
        path: "/data-type",
      },
      {
        title: "Factory Status",
        path: "/factory-status",
      },
      {
        title: "HTS Material",
        path: "/hts-material",
      },
      {
        title: "HTS Material Detail",
        path: "/hts-material-detail",
      },
      {
        title: "Function",
        path: "/function",
      },
      {
        title: "Manage Tags",
        path: "/tag",
      },
      {
        title: "Manufacturing Method",
        path: "/manufacturing-method",
      },
      {
        title: "Marketing Designer",
        path: "/marketing-designer",
      },
      {
        title: "Primary Material",
        path: "/primary-material",
      },
      {
        title: "Finishes",
        path: "/finishes",
      },
      // {
      //   title: "Project Coordinator",
      //   path: "/project-coordinator",
      // },
      {
        title: "Project Stage",
        path: "/project-stage",
      },
      {
        title: "Project Status",
        path: "/project-status",
      },
      {
        title: "Royalty Designer",
        path: "/royalty-designer",
      },
      {
        title: "Secondary Material",
        path: "/secondary-material",
      },
      {
        title: "Stockcode Image Hierarchy",
        path: "/stockcode-image-hierarchy",
      },
      {
        title: "Sku Type",
        path: "/sku-type",
      },
      {
        title: "Style",
        path: "/style",
      },
      {
        title: "Suppliers",
        path: "/suppliers",
      },
    ],
  },
];
